import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import _ from 'lodash';
import classNames from 'classnames';

import './MessageScreen.scss';
import Card from "../../components/Card/Card";
import AnimatedElement from "../../components/AnimatedElement/AnimatedElement";
import Button from "../../components/Button/Button";

import imgQuestion from './img/question.jpg';
import finishQuestion from './img/finish.jpg';
import PlayArea from "../../lib/PlayArea";

const ANIMATION_SPEED = 1000;

const IMAGES = {
  'question': imgQuestion,
  'finish': finishQuestion,
  'colorful': require('./img/colorful.jpg'),
};

export default class MessageScreen extends Component {
  static propTypes = {
    parameters: PropTypes.shape({
      header: PropTypes.string,
      content: PropTypes.string,
      image: PropTypes.oneOf(['question', 'finish', 'colorful']),
      imageUrl: PropTypes.string,
      imageUrlVertical: PropTypes.string,
      buttonText: PropTypes.string,
      refreshOnNext: PropTypes.bool,
      customUrlForNext: PropTypes.string,
    }),
    image: PropTypes.string,
    imageFormat: PropTypes.string,
    header: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    html: PropTypes.bool,
    bigImage: PropTypes.bool,
    buttonText: PropTypes.string,
    browserWarning: PropTypes.bool,
    forVisa: PropTypes.bool,


    nextEnabled: PropTypes.bool,
    refreshEnabled: PropTypes.bool,

    goNextAction: PropTypes.func,
  };

  static defaultProps = {
    goNextAction: () => {},
  };

  static defaultParameters = {
    image: "question",
    imageFull: false,
    buttonText: "Przejdź dalej",
    animation: AnimatedElement.AnimationTypes.fade,
    elevator: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  goNext = () => {
    this.setState({
      visible: false,
    });

    setTimeout(this._goNext, ANIMATION_SPEED);
  };

  _goNext = () => {
    const {refreshOnNext, customUrlForNext} = this.props.parameters;

    if (refreshOnNext) {
      this.reload();
    } else if (customUrlForNext) {
      this.goToUrl(customUrlForNext);
    } else {
      this.props.goNextAction();
    }
  };

  reload = () => {
    window.location.reload();
  };

  goToUrl = (url) => {
    window.location.href = url;
  };

  render() {
    let {header, content, image, imageUrl, imageUrlVertical, imageFull, buttonText, animation, elevator} = _.defaults(this.props.parameters, MessageScreen.defaultParameters);
    image = IMAGES[image];
    if (imageUrl) {
      if (imageUrlVertical && PlayArea.isVertical())
        image = imageUrlVertical;
      else
        image = imageUrl;
    }

    const imageStyle = {
      backgroundImage: `url('${image}')`
    };

    return (
      <AnimatedElement className={classNames('MessageScreen', {"full-image": imageFull})} visible={this.state.visible} animation={animation}>
        <Card className="message">
          {elevator &&
            <div className="doors">
              <div className="left-door" />
              <div className="right-door" />
            </div>
          }
          <div className={'image'} style={imageStyle}>
            <img src={image} alt={image}/>
          </div>
          {(header || content) &&
          <div className={'content'}>
            <h1>{header}</h1>
            <ReactMarkdown source={content}/>
          </div>
          }
        </Card>
        <Button onClick={this.goNext} big>
          {buttonText}
        </Button>
      </AnimatedElement>
    );
  }
}
