import React, {useEffect, useRef} from 'react';

import Card from "sprint/components/Card/Card";
import Images from "sprint/lib/Images";

import "./MatchCategories.scss";
import {isPointInRectangle} from "../../../lib/Geometry";



export default function MatchCategories({categories, draggedPosition, onCategoryHit}) {
  const categoryHitAction = (categoryId) => () => {
    onCategoryHit(categoryId);
  };

  return (
    <div className="MatchCategories">
      {Object.entries(categories).map(([index, category]) =>
        <MatchCategory key={index} category={category}
          draggedPosition={draggedPosition} onCategoryHit={categoryHitAction(index)}
        />
      )}
    </div>
  )
}

function MatchCategory({category, draggedPosition, onCategoryHit}) {
  const categoryRef = useRef(null);

  useEffect(() => {
    function wasDraggedToCategory() {
      if (draggedPosition) {
        const cardRect = categoryRef.current.getBoundingClientRect();
        return isPointInRectangle(draggedPosition, cardRect);
      }

      return false
    }

    if (wasDraggedToCategory()) {
      onCategoryHit();
    }
  }, [draggedPosition, onCategoryHit]);

  function getImageStyle() {
    return {
      backgroundImage: Images.getCSSImageUrl(
        category.image,
        Images.SIZES.LARGE,
        Images.PROPORTIONS.P3x2
      )
    };
  }

  return (
    <Card className="category" innerRef={categoryRef}>
      <div className="image" style={getImageStyle()} />
      <div className="name">{category.name}</div>
    </Card>
  )
}