import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import _ from 'underscore';

import Card from "../components/Card";

import './css/OpenQuestionExercise.css'
import Results from "../structures/Results";

const ANIMATION_SPEED = 1000;

class OpenQuestionExercise extends Component {

  static propTypes = {
    instructionPhrases: PropTypes.string,
    answers: PropTypes.array,
    instructionDescription: PropTypes.string,
    pointsString: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,

      playing: true,

      answers: _.clone(props.answers),
      chosenAnswer: null,

      enteredText: '',

      message: '',

      submitEnabled: true,
    };

    for (let answerIndex in this.state.answers) {
      if (this.state.answers.hasOwnProperty(answerIndex)){
        let content = this.state.answers[answerIndex];
        this.state.answers[answerIndex] = {
          content: content,
          visible: true,
        }
      }
    }

    this.goNext = this.goNext.bind(this);

    this._goNext = this._goNext.bind(this);
  }

  answerChosen = (answer) => {
    if (!this.state.playing) {
      return;
    }

    this.setState({
      chosenAnswer: answer,
    })
  };

  textEdited = (event) => {
    this.setState({
      enteredText: event.target.value
    });
  };

  goNext = () => {
    if (!this.state.visible) {
      return;
    }
    new Audio('/sounds/click.mp3').play();
    this.setState({
      visible: false,
    });

    setTimeout(this._goNext, ANIMATION_SPEED);
  };

  _goNext() {
    let results = new Results();
    results.other = this.state.chosenAnswer.content + ': ' + this.state.enteredText;

    this.props.goNextAction(results);
  }

  render() {
    let answers = this.state.answers.map((answer, i) => {
      let fullWidth = this.state.answers.length % 2 === 1 && i === this.state.answers.length - 1;
      let isChosenAnswer = answer === this.state.chosenAnswer;
      return [
        <Card
          answer={answer}
          classes={'answer' + (isChosenAnswer ? ' chosen' : '') + (fullWidth ? ' full' : '')}

          type="text"
          side={answer.side}
          index={i}
          columns={1}

          onClick={this.answerChosen.bind(this, answer)}

          key={i}
        >
          <p>{ answer.content }</p>
        </Card>,
        ' ',
      ]
    });

    let imageStyle = {
      'backgroundImage': "url('images/message/colorful-pencils.jpg')"
    };

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
        <div className="OpenQuestionExercise">
          <div className="pure-g instruction-container">
            <div className="pure-u-1-1">
              <Card classes="question">
                <p>{this.props.instructionPhrases}</p>
              </Card>
            </div>
          </div>
          <div className="pure-g answers-container">
            <div className="pure-u-2-5 image-container">
              <Card className={'image'}>
                <div style={imageStyle} />
              </Card>
            </div>
            <div className="pure-u-3-5 answers">
              {answers}
            </div>
          </div>
          <CSSTransitionGroup
            transitionName="slide"
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionAppear={true}
            transitionAppearTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
          {this.state.message &&
          <div className="pure-g messages-container">
            <div className="pure-u-1-1 messages-row">
              <Card classes="message">
                <p>{this.state.message}</p>
              </Card>
            </div>
          </div>
          }
          </CSSTransitionGroup>
          <div className="pure-g description-container">
            <div className="pure-u-1-1 description">
              <Card>
                <div className="text">
                  <p className="instruction">{this.props.instructionDescription}</p>
                  <textarea value={this.state.value} onChange={this.textEdited}/>
                </div>
              </Card>
            </div>
          </div>
          <CSSTransitionGroup
            transitionName="example"
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {(this.state.chosenAnswer !== null && this.state.enteredText.length > 10) &&
            <div className="pure-g buttons-container">
              <div className="pure-u-1-1 center">
                <Card classes={"next-button" + (this.state.submitEnabled ? '' : ' disabled')} onClick={this.goNext}>
                  <p>
                    {this.state.submitEnabled ? 'Prześlij' : [<i className="fas fa-spinner fa-spin" key={0}/>, ' Przesyłam...']}
                  </p>
                </Card>
              </div>
            </div>
            }
          </CSSTransitionGroup>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}

export default OpenQuestionExercise;
