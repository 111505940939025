import React, {Component} from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import './css/AnswerCard.css';
import Card from "./Card";

class AnswerCard extends Component {
  static propTypes = {
    answer: PropTypes.object,

    side: PropTypes.string,
    index: PropTypes.number,
    columns: PropTypes.number,

    classes: PropTypes.string,
    clickCallback: PropTypes.func,

    front: PropTypes.node,
    back: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.makeCallback = this.makeCallback.bind(this);
    this.callback = _.throttle(this.props.clickCallback, 500, {trailing: false});
  }

  makeCallback(event) {
    event.persist();
    this.callback(this.props.answer, this.props.index, event);
  }

  render() {
    let index = this.props.index;
    let columns = this.props.columns;
    let position = '';

    if (columns === 3) {
      if (((index + 1) % columns) === 1) {
        position += 'left';
      } else if (((index + 1) % columns) === 0) {
        position += 'right';
      } else {
        position += 'center';
      }
    } else {
      position += 'center';
    }

    let style = this.props.style ? _.clone(this.props.style) : {};
    let visibilityClass = 'visible';
    if (!this.props.answer.visible) {
      style['opacity'] = 0;
      visibilityClass = 'invisible';
    }

    return (
      <div className={'AnswerCard'
          + (this.props.classes ? ' ' + this.props.classes : '')
          + ' pure-u-1-' + columns
          + ' ' + position
          + ' ' + visibilityClass
        }
        style={style}
      >
          <Card classes={'answer' + (this.props.side === 'front' ? '' : ' flip')} type={Card.TYPES.bordered}>
            <div className='front' onClick={this.makeCallback}>
              {this.props.front}
            </div>
            <div className='back'>
              {this.props.back}
            </div>
          </Card>
      </div>
    )
  }
}

export class QuestionAnswerCard extends Component {
  static propTypes = {
    answer: PropTypes.object,

    classes: PropTypes.string,
    type: PropTypes.string,
    columns: PropTypes.number,
    side: PropTypes.string,

    index: PropTypes.number,

    clickCallback: PropTypes.func,
  };

  static defaultProps = {
    classes: '',
    type: 'image',
  };

  render() {
    let answer = this.props.answer;
    let front = null;
    if (this.props.type === 'image') {
      let style = {};
      let imgSrc = 'images/questions/' + answer.img;
      style['backgroundImage'] = 'url("' + imgSrc + '")';

      front = (
        <div className="image" style={style} />
      );
    } else if (this.props.type === 'text') {
      front = (
        <div className="text">
          <div className="vertical-helper" />
          <div className="content">
            <p>{answer.content}</p>
          </div>
        </div>
      )
    }


    let feedbackIcon = null;
    let feedbackClass = null;
    let feedbackMessage = null;
    if (answer.correct) {
      feedbackClass = 'success';
      feedbackIcon = <i className="fas fa-check" />;
      feedbackMessage = {__html: '<div class="vertical-helper"></div><div class="feedback-text">Świetnie!</div>'};
    } else {
      feedbackClass = 'error';
      feedbackIcon = <i className="fas fa-times" />;
      feedbackMessage = {__html: '<div class="vertical-helper"></div><div class="feedback-text">Upss...<br /><span class="smaller">Spróbuj jeszcze raz</span></div>'};
    }

    let back = (
      <div className={'feedback ' + feedbackClass}>
        {/*<div className="feedback-container">*/}
          <div className="pure-u-2-5 icon">
              <div className="vertical-helper"/>
              <div className="feedback-icon">{feedbackIcon}</div>
            </div>
          <p className="pure-u-3-5 text" dangerouslySetInnerHTML={feedbackMessage}/>
        {/*</div>*/}
      </div>
    );

    return (
      <AnswerCard
        classes={'QuestionAnswerCard ' + this.props.classes}

        answer={this.props.answer}

        side={this.props.side}
        index={this.props.index}
        columns={this.props.columns}

        clickCallback={this.props.clickCallback}

        front={front}
        back={back}
      />
    )
  }
}

const IMAGES = {
  1: 'autumn',
  2: 'forest',
  3: 'iceberg',
  4: 'lake',
  5: 'lighthouse',
  6: 'mountains',
  7: 'reef',
  8: 'waterfall',
  9: 'winter-forest',
  10: 'winter-mountains',
};

export class MemoryAnswerCard extends Component {
  static propTypes = {
    answer: PropTypes.object,

    columns: PropTypes.number,
    side: PropTypes.string,

    index: PropTypes.number,

    clickCallback: PropTypes.func,
  };

  render() {
    let answer = this.props.answer;
    let style = {
      backgroundImage: "url('/images/memory/" + IMAGES[answer.id] + ".jpg')"
    };

    let front = (
      <div className="image" />
    );

    let back = (
      <div className="symbol" style={style}>
        {/*<div className="vertical-helper"/>*/}
        {/*<div className="icon">*/}
          {/*<i className={'fas fa-' + symbol} />*/}
        {/*</div>*/}
      </div>
    );

    return (
      <AnswerCard
        classes='MemoryAnswerCard'

        answer={this.props.answer}

        side={this.props.side}
        index={this.props.index}
        columns={this.props.columns}

        clickCallback={this.props.clickCallback}

        front={front}
        back={back}
      />
    )
  }
}

export default AnswerCard;