import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import circleImg from './img/circle.svg';
import wheelImg from './img/wheel.svg';
import skullImg from './img/skull.svg';
import xImg from './img/x.svg';

import './AnimationIncorrectExplosion.scss';

export default class AnimationIncorrectExplosion extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,

    fixed: PropTypes.bool,

    position: PropTypes.array,
    x: PropTypes.number,
    y: PropTypes.number,
  };

  render() {
    const {visible, fixed} = this.props;

    if (visible)
      return (
        <div className={classNames("AnimationIncorrectExplosion", "visible", {fixed})} style={this.getStyling()}>
          <img alt={'Bum'} className={`animation-incorrect-explosion animation-incorrect-explosion-circle`} src={circleImg} />
          {_.range(1, 5).map((index) => {
            return <img key={index} alt={'Bum'} className={`animation-incorrect-explosion animation-incorrect-explosion-x-${index}`} src={xImg} />
          })}
          {_.range(1, 3).map((index) => {
            return <img key={index} alt={'Bum'} className={`animation-incorrect-explosion animation-incorrect-explosion-skull-${index}`} src={skullImg} />
          })}
          {_.range(1, 4).map((index) => {
            return <img key={index} alt={'Bum'} className={`animation-incorrect-explosion animation-incorrect-explosion-wheel-${index}`} src={wheelImg} />
          })}
        </div>
      );
    else
      return "";
  }

  getStyling = () => {
    let {position, x, y} = this.props;

    if (position) {
      x = position[0];
      y = position[1];
    }

    return {
      left: `${x}px`,
      top: `${y}px`,
    }
  };
}