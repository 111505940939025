import Exercises from "../structures/Exercises";

const QUIZ = {
  helpText: 'Wybierz prawidłową odpowiedź na wyświetlone pytanie',

  timeToAnswerInSec: 20,
  questions: [
    {
      question: "Najbardziej efektywnymi technikami uczenia są:",
      answers: [
        {
          content: "Nauczanie innych i nauka przez praktykę",
          correct: true,
        },
        {
          content: "Demonstracja i nauka przez praktykę",
          correct: false,
        },
        {
          content: "Nauczanie innych i dyskusja w grupie",
          correct: false,
        },
        {
          content: "Nauczanie innych i wykład",
          correct: false,
        },
      ]
    },
    // {
    //   question: "Burzę mózgów robimy aby:",
    //   answers: [
    //     {
    //       content: "Zebrać różne pomysły",
    //       correct: true,
    //     },
    //     {
    //       content: "Zbadać opinię uczestników szkolenia",
    //       correct: false,
    //     },
    //     {
    //       content: "Przeprowadzić głosowanie",
    //       correct: false,
    //     },
    //     {
    //       content: "Otworzyć ludzi na doświadczenia innych",
    //       correct: false,
    //     },
    //   ]
    // },
    // {
    //   question: "Okres godowy jeżów trwa:",
    //   answers: [
    //     {
    //       content: "Od kwietnia do lipca",
    //       correct: true,
    //     },
    //     {
    //       content: "Od kwietnia do sierpnia",
    //       correct: false,
    //     },
    //     {
    //       content: "Od maja do lipca",
    //       correct: false,
    //     },
    //     {
    //       content: "Od lipca do sierpnia",
    //       correct: false,
    //     },
    //   ]
    // },
    // {
    //   question: "Cykl Kolba to:",
    //   answers: [
    //     {
    //       content: "Wiedza, zastosowanie, doświadczenie, refleksja",
    //       correct: true,
    //     },
    //     {
    //       content: "Doświadczenie, refleksja, zastosowanie, wdrażanie",
    //       correct: false,
    //     },
    //     {
    //       content: "Wiedza, doświadczenie, zastosowanie, refleksja",
    //       correct: false,
    //     },
    //     {
    //       content: "Eksperyment, wiedza, refleksja, wdrażanie",
    //       correct: false,
    //     },
    //   ]
    // },
    // {
    //   question: "Które pytanie jest przykładem pytania ilościowego",
    //   answers: [
    //     {
    //       content: "Proszę o podniesienie ręki przez tych z Was, którzy dzisiaj rano pili alkohol?",
    //       correct: true,
    //     },
    //     {
    //       content: "Jak często Wam się zdarza pić rano alkohol?",
    //       correct: false,
    //     },
    //     {
    //       content: "Ile razy w ciągu dnia pijecie alkohol?",
    //       correct: false,
    //     },
    //     {
    //       content: "Ile razy mam Ci mówić, abyś nie pił alkoholu przed szkoleniem?",
    //       correct: false,
    //     },
    //   ]
    // },
    // {
    //   question: "Omawiając wykres należy (znajdź błędną informację):",
    //   answers: [
    //     {
    //       content: "W obrazowy sposób przedstawić wszystkie dane",
    //       correct: true,
    //     },
    //     {
    //       content: "Porównywać dane a nie omawiać pojedyncze liczby",
    //       correct: false,
    //     },
    //     {
    //       content: "Omówić najważniejsze dane",
    //       correct: false,
    //     },
    //     {
    //       content: "Skupić się na najważniejszym wniosku wypływającym z badania",
    //       correct: false,
    //     },
    //   ]
    // },
    // {
    //   question: "Które ze sformułowań jest obrazowym mówieniem?",
    //   answers: [
    //     {
    //       content: "Co druga osoba jest narażona",
    //       correct: true,
    //     },
    //     {
    //       content: "Problem występuje w 30% sytuacji",
    //       correct: false,
    //     },
    //     {
    //       content: "Analiza finansowa",
    //       correct: false,
    //     },
    //     {
    //       content: "Szybkie dostosowywanie się do zmian",
    //       correct: false,
    //     },
    //   ]
    // },
    // {
    //   question: "W.I.I.F.M to skrót od słów:",
    //   answers: [
    //     {
    //       content: "What's in it for me",
    //       correct: true,
    //     },
    //     {
    //       content: "What is in for me",
    //       correct: false,
    //     },
    //     {
    //       content: "Where is it formal message",
    //       correct: false,
    //     },
    //     {
    //       content: "Why it is for me",
    //       correct: false,
    //     },
    //   ]
    // },
    // {
    //   question: "YW to skrót od słów:",
    //   answers: [
    //     {
    //       content: "Nie ma za co",
    //       correct: true,
    //     },
    //     {
    //       content: "Jesteś w błędzie",
    //       correct: false,
    //     },
    //     {
    //       content: "Jesteś wspaniały",
    //       correct: false,
    //     },
    //     {
    //       content: "Marnujesz swój czas",
    //       correct: false,
    //     },
    //   ]
    // },
    // {
    //   question: "Jan Himilsbach zagrał w filmie:",
    //   answers: [
    //     {
    //       content: "Wniebowzięci",
    //       correct: true,
    //     },
    //     {
    //       content: "Miś",
    //       correct: false,
    //     },
    //     {
    //       content: "50 twarzy Greya",
    //       correct: false,
    //     },
    //     {
    //       content: "Nie lubię poniedziałków",
    //       correct: false,
    //     },
    //   ]
    // },
  ]
}

let EXERCISES_TEST = new Exercises();
export default EXERCISES_TEST;

EXERCISES_TEST.loginNeeded = true
EXERCISES_TEST.onlyUsernameLogin = true
EXERCISES_TEST.inLoop = false
EXERCISES_TEST.shouldSendResults = true
EXERCISES_TEST.showSummary = false
EXERCISES_TEST.groups = [
  {
    name: 'Quiz',
    exercises: [
      {
        id: 'quiz',
        type: 'quiz',
        parameters: QUIZ,
      },
    ]
  }
]
