import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Card from "../components/Card";

import './css/LoginScreen.scss'
import Animation from "../components/Animation";

const ANIMATION_SPEED = 1000;
const USE_REMOTE_LOGIN = true;
const LOCAL_USER = 'test';
const LOCAL_PASS = 'test';

const USERNAME_VALIDATION_RE = /^[A-Za-z0-9_\- ęóąśłżźćńĘÓĄŚŁŻŹĆŃ]+$/;

class LoginScreen extends Component {
  static propTypes = {
    'loginAction': PropTypes.func,
    'shouldCreateUser': PropTypes.bool,
    'serverAddress': PropTypes.string,
    generateLoginIfNeeded: PropTypes.bool,

    passwordForGeneratedLogin: PropTypes.bool,
    numericPassword: PropTypes.bool,
  };

  static defaultProps = {
    'shouldCreateUser': false,
    generateLoginIfNeeded: false,
    passwordForGeneratedLogin: false,
    numericPassword: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      submitDisabled: false,

      message: '',
      messageType: 'error',

      username: '',
      password: '',
    };

    this.loggedIn = this.loggedIn.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  _showLogin = () => {
    this.setState({
      visible: true,
      submitDisabled: false,
    })
  }

  _createUser(username, password, showMessages, onSuccess) {
    return this._checkLogin(this.props.serverAddress + 'auth/create', {
      username: username,
      password: password,
    }, showMessages, onSuccess)
  }

  _checkLoginWithToken(username, token, showMessages, onSuccess, onFailure) {
    return this._checkLogin(this.props.serverAddress + 'auth/token', {
      username: username,
      token: token,
    }, showMessages, onSuccess, onFailure)
  }

  _checkLogin = (address, data, showMessages = true, onSuccess = () => {}, onFailure) => {
    axios({
      method: 'POST',
      url: address,
      data: data,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then((result) => {
      if (result.data['status'] === 'Success') {
        if (showMessages) {
          this.setState({
            message: 'Zalogowano!',
            messageType: 'success',
          });
        }

        onSuccess(result.data['token'])
      } else {
        if (onFailure) {
          onFailure()
        } else {
          this._showLogin();
          if (showMessages) {
            this.setState({
              message: 'Zmień nazwę użytkownika lub wprowadź poprawne hasło',
              messageType: 'error',

            })
          }
        }
      }
    }).catch((error) => {
      this._showLogin();
      if (showMessages) {
        this.setState({
          message: 'Zmień nazwę użytkownika lub wprowadź poprawne hasło',
          messageType: 'error',
        })
      }
    })
  }

  componentDidMount() {
    let username = window.localStorage.getItem('username');
    let token = window.localStorage.getItem('token');

    if (!username || !token) {
      if (this.props.generateLoginIfNeeded) {
        this.createTemporaryUser();
      } else {
        this._showLogin();
      }
    } else {
      this._checkLoginWithToken(username, token, false,
        (token) => {
        window.localStorage.setItem('token', token);
        this.loggedIn(username);
      }, () => {
          if (this.props.generateLoginIfNeeded) {
            this.createTemporaryUser();
          } else {
            this._showLogin();
          }
      })
    }
  }

  createTemporaryUser = () => {
    let usernameWithToken = LoginScreen.generateToken(LoginScreen.generateRandomUsername());
    this._createUser(usernameWithToken, false, (token) => {
      // onSuccess
      window.localStorage.setItem('username', usernameWithToken);
      window.localStorage.setItem('token', token);

      setTimeout(() => {this.loggedIn(usernameWithToken)}, ANIMATION_SPEED);
    })
  };

  loggedIn(username) {
    this.setState({
      'visible': false,
    });

    setTimeout(() => {this.props.loginAction(username)}, ANIMATION_SPEED);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  static generateRandomUsername() {
    return Math.random().toString(36).substr(2, 12)
  }

  static generateToken(username) {
    return username + '#' + Math.random().toString(36).substr(2, 8)
  }

  handleFormSubmit(event) {
    event.preventDefault();
    this.setState({
      submitDisabled: true,
    });
    const { username, password } = this.state;
    const {shouldCreateUser, passwordForGeneratedLogin} = this.props

    if (!USERNAME_VALIDATION_RE.test(username)) {
      this.setState({
        message: 'Wpisz tylko litery i liczby jako nazwę użytkownika.',
        messageType: 'error',
        submitDisabled: false
      })
    } else if (username.length < 3) {
      this.setState({
        message: 'Nazwa użytkownika musi mieć co najmniej 3 znaki.',
        messageType: 'error',
        submitDisabled: false
      })
    } else if (password.length < 3) {
      this.setState({
        message: 'Hasło musi mieć co najmniej 3 znaki.',
        messageType: 'error',
        submitDisabled: false
      })
    } else if (shouldCreateUser) {
      let createdUsername = username;
      if (!passwordForGeneratedLogin) {
        createdUsername = LoginScreen.generateToken(createdUsername);
      }


      this._createUser(createdUsername, password, true, (token) => {
        // onSuccess
        window.localStorage.setItem('username', createdUsername);
        window.localStorage.setItem('token', token);

        setTimeout(() => {this.loggedIn(createdUsername)}, ANIMATION_SPEED);
      })
    } else if (!USE_REMOTE_LOGIN) {
      setTimeout(() => {
        if (username === LOCAL_USER && password === LOCAL_PASS) {
          this.setState({
            message: 'Zalogowano!',
            messageType: 'success',
          });
          setTimeout(() => {
            this.loggedIn(username)
          }, ANIMATION_SPEED);
        } else {
          this.setState({
            message: 'Nieprawidłowe dane logowania',
            messageType: 'error',
            submitDisabled: false,
          });
        }
      }, 1000);
    } else {
      axios({
        method: 'POST',
        url: this.props.serverAddress + 'auth/login',
        data: {
          'username': username,
          'password': password
        },
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }).then((result) => {
        if (result.data['status'] === 'Success') {
          window.localStorage.setItem('username', username);
          window.localStorage.setItem('token', result.data['token']);
          this.setState({
            message: 'Zalogowano!',
            messageType: 'success',
          });
          setTimeout(() => {this.loggedIn(username)}, ANIMATION_SPEED);
        } else {
          this.setState({
            message: 'Nieprawidłowe dane logowania',
            messageType: 'error',
            submitDisabled: false,
          });
        }
      }).catch((error) => {
        this.setState({
          message: 'Wystąpił problem z połączeniem - spróbuj jeszcze raz',
          messageType: 'error',
          submitDisabled: false,
        });
      });
    }
  }

  render() {
    const {shouldCreateUser, passwordForGeneratedLogin, numericPassword} = this.props;

    return (
      <Animation active={this.state.visible} type={Animation.TYPES.fade}>
        <div className="LoginScreen">
          <div className="pure-g title-container">
            <div className="pure-u-1-5"/>
            <div className="pure-u-3-5">
              <Card className={'login'}>
                <div className="logotype">
                  <img src="/images/logotype-blue.png" alt="SprinTECH Learning" />
                </div>
                <div className="login-form">
                  <form className="pure-form pure-form-aligned"
                        onSubmit={this.handleFormSubmit}
                  >
                    <fieldset>
                      <div className="pure-control-group">
                        <label htmlFor="name">Użytkownik</label>
                        <input name="username" type="text" placeholder="Nazwa użytkownika"
                               value={this.state.username}
                               onChange={this.handleInputChange}
                        />
                      </div>

                      {!(shouldCreateUser && !passwordForGeneratedLogin) &&
                        <div className="pure-control-group">
                          <label htmlFor="password">{numericPassword ? "Hasło (tylko cyfry)" : "Hasło"}</label>
                          <input name="password" type={numericPassword ? "number" : "password"}
                            pattern={numericPassword ? "[0-9]*" : undefined}
                            value={this.state.password}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      }
                      <div className={'message ' + this.state.messageType}>
                        {this.state.message}
                      </div>
                      <button type="submit" className="pure-button pure-button-primary login-button" disabled={this.state.submitDisabled}>{this.state.submitDisabled ? <span><i className="fas fa-spinner fa-spin"/> Loguję...</span> : <span>Zaloguj</span>}</button>
                    </fieldset>
                  </form>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </Animation>
    );
  }
}

export default LoginScreen;
