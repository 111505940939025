import {Component} from "react";
import _ from 'lodash';

export default class MyComponent extends Component {
  currentStateCallbackTimeout;

  static myConstructor(props, state, startingState = 0) {
    state.current = startingState;
    state.questions = _.clone(props.questions);
    state.questionIndex = 0;
  }

  static shuffleAnswers(state) {
    for (let question of state.questions) {
      question.answers = _.shuffle(question.answers);
    }
  }

  static processAnswers(state, defaultValues) {
    for (let question of state.questions) {
      for (let answer of question.answers) {
        _.defaults(answer, defaultValues);
      }
    }
  }

  componentWillUnmount() {
    this.myComponentWillUnmount();
  }

  myComponentWillUnmount() {
    clearTimeout(this.currentStateCallbackTimeout);
  }

  setCurrentState(newState, callback = () => {}, callbackDelay) {
    clearTimeout(this.currentStateCallbackTimeout);
    let callbackFunction = callback;

    if (callbackDelay) {
      callbackFunction = () => { this.currentStateCallbackTimeout = setTimeout(callback, callbackDelay) }
    }

    this.setState({
      current: newState
    }, callbackFunction);
  }


  delayedSetCurrentState(newState, delayMs, callback = () => {}, callbackDelay) {
    setTimeout(this.setCurrentState.bind(this, newState, callback, callbackDelay), delayMs);
  }

  setCurrentStateDelayed = this.delayedSetCurrentState;

  setCurrentStateSequence(stateList, delayMs, callback = () => {}, callbackDelay) {
    let stateStack = stateList.slice(0).reverse();
    this.setCurrentStateStack(stateStack, delayMs, callback, callbackDelay);
  }

  setCurrentStateStack(stateStack, delayMs, callback = () => {}, callbackDelay) {
    let currentState = stateStack.pop();
    if (stateStack.length > 0) {
      this.setCurrentState(currentState, () => {
        setTimeout(this.setCurrentStateStack.bind(this, stateStack, delayMs, callback), delayMs);
      });
    } else {
      this.setCurrentState(currentState, callback, callbackDelay);
    }
  }

  inState = (state) => {
    if (Array.isArray(state)) {
      return state.includes(this.state.current)
    } else {
      return this.state.current === state
    }
  };

  inStates = this.inState;

  finish = (sendPoints = true, otherData) => {
    let data = {};
    if (sendPoints) {
      data['points'] = this.state.points;
    }
    if (otherData) {
      data['other'] = otherData;
    }

    this.props.onFinish(data);
  };
}