import React, { Component } from "react"
// import injectTapEventPlugin from "react-tap-event-plugin";

import Sounds from '../lib/Sounds'
import Animation from "../components/Animation";
import PointsBar from "../components/PointsBar";
import {ButtonCard} from "../components/Card";
import {DEFAULT_ANIMATION_SPEED} from "../controllers/SprintController";

import "./css/MeditationExercise.scss"
import Velocity from "velocity-animate";
import {pxString} from "../utils/styling";
import Card from "../components/Card";

export const EXAMPLE_CONFIG = [
  {
    "name": "Medytacja",
    "exercises": [
      {
        "id": "meditation",
        "type": "meditation",
        "parameters": {
          "duration": [3, 5, 10],
          "helpText": "Przy każdym wdechu naciśnij w dowolne miejsce ekranu. Co piąty wdech naciśnij dwukrotnie"
        }
      }
    ]
  }
]

const DOUBLE_TAP_INTERVAL_MS = 2500;
const INACTIVITY_INTERVAL_MS = 16000;

const TUTORIAL_TEXT = [
  'Skoncentruj się na swoim oddechu. Za każdym razem kiedy zrobisz wydech, dotknij raz ekranu. Zrób to teraz',
  'Świetnie! Rób tak po każdym wydechu. Co piąty wydech zaakcentuj dwoma dotknięciami. Spróbuj zrobić to teraz.',
  'Doskonale! Sygnał dźwiękowy, to informacja dla Ciebie o przegapionym podwójnym lub pojedynczym kliknięciu. Od tej pory musisz zacząć liczyć oddechy od nowa.'
];

export default class MeditationExercise extends Component {
  //////////
  // region Class variables
  //////////
  doubleTapTimeout
  inactivityTimeout
  tapCount = 0
  totalTapCount = 0
  doubleTapping = false
  tapContainerRef
  tapCircleRef

  circlePosition

  STATES = {
    STARTING: 0,
    TUTORIAL: 1,
    CHOOSING_DURATION: 2,
    BREATHING: 3,
    SUMMARY: 4,
    FINISHED: 5,
  }
  //////////
  // endregion Class variables
  //////////

  //////////
  // region React functions
  //////////
  ___reactFunctions___() {}

  constructor(props) {
    super(props)

    this.state = {
      visible: true,

      currentStep: this.STATES.STARTING,

      duration: 0,
      points: 0,

      tutorial: {
        step: 0,
      }
    }

    this.tapContainerRef = React.createRef()
    this.tapCircleRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    clearTimeout(this.doubleTapTimeout)
    clearTimeout(this.inactivityTimeout)
  }

  onResize = () => {
    this.clearCirclePosition()
  }

  render() {
    return (
      <Animation type="fade" active={this.state.visible}>
        <div className="MeditationExercise">
          <Animation type="fade" active={this.state.currentStep === this.STATES.BREATHING} delay_ms={DEFAULT_ANIMATION_SPEED}>
            <div className="points-bar-container pure-g">
              <div className="pure-u-1-1">
                <PointsBar
                  points={this.state.points} negativePointsAllowed={true}
                  timeLimit={this.state.duration} onTimeRanOut={this.timeRanOut}
                  clockRunning={this.state.currentStep === this.STATES.BREATHING} clockId={this.state.currentStep}
                />
              </div>
            </div>
          </Animation>
          {/*
            STARTING
          */}
          <Animation type="fade" active={this.state.currentStep === this.STATES.STARTING} className="starting-step">
            <Card className="exercise-image">
              <p>Koncentracja</p>
              <img src="/images/square/512/kropla.jpg" alt="Kropla wody"/>
            </Card>
            <div className="buttons-container">
              <ButtonCard onClick={this.startTutorial}>
                Pokaż, jak ćwiczyć
              </ButtonCard>
              <ButtonCard onClick={this.changeStep.bind(this, this.STATES.CHOOSING_DURATION)}>
                Rozpocznij ćwiczenie
              </ButtonCard>
            </div>
          </Animation>

          {/*
            TUTORIAL
          */}
          <Animation type="fade" active={this.state.currentStep === this.STATES.TUTORIAL} delay_ms={DEFAULT_ANIMATION_SPEED}
                     className="tutorial-step">
            <Card className="exercise-instruction">
              <p key={this.state.tutorial.step}>{ TUTORIAL_TEXT[this.state.tutorial.step] }</p>
            </Card>
            <Animation type={Animation.TYPES.fade} active={this.state.tutorial.step < 2}>
              <div className='tap-circle-container'>
                <div className="tap-circle" ref={this.tapCircleRef} />
              </div>
              <div className='tap-container' onMouseUp={this.tapped} onTouchEnd={this.tapped} ref={this.tapContainerRef} />
            </Animation>
            <Animation type={Animation.TYPES.fade} active={this.state.tutorial.step === 2 && this.state.currentStep === this.STATES.TUTORIAL} delay_ms={DEFAULT_ANIMATION_SPEED}>
              <div className="buttons-container">
                <ButtonCard onClick={this.changeStep.bind(this, this.STATES.CHOOSING_DURATION)}>
                  Rozpocznij ćwiczenie
                </ButtonCard>
              </div>
            </Animation>
          </Animation>

          {/*
            CHOOSING DURATION
          */}
          <Animation type="fade" active={this.state.currentStep === this.STATES.CHOOSING_DURATION}
                     delay_ms={DEFAULT_ANIMATION_SPEED} className="choosing-step">
            <Card className="exercise-parameters">
              <h1>Wybierz czas ćwiczenia aby rozpocząć</h1>
            </Card>
            <div className="buttons-container exercise-duration">
              {this.props.duration.map((duration, index) => {
                return (
                  <ButtonCard onClick={this.startGame.bind(this, duration)} key={index}>
                    { duration } min.
                  </ButtonCard>
                )
              })}
            </div>
          </Animation>

          {/*
            BREATHING
          */}
          <Animation type="fade" active={this.state.currentStep === this.STATES.BREATHING} delay_ms={DEFAULT_ANIMATION_SPEED}>
            <div className='tap-circle-container'>
              <div className="tap-circle" ref={this.tapCircleRef} />
            </div>
            <div className='tap-container' onMouseUp={this.tapped} onTouchEnd={this.tapped} ref={this.tapContainerRef} />
          </Animation>

          {/*
            FINISHED
          */}
          <Animation type="fade" active={this.state.currentStep === this.STATES.SUMMARY} delay_ms={DEFAULT_ANIMATION_SPEED}>
            <Card className="summary">
              <p>Liczba błędów: <strong>{ -this.state.points }</strong></p>
              <p className="smaller">Liczba oddechów: <strong>{ this.totalTapCount }</strong></p>
              <p className="smaller">Średni czas między oddechami: <strong> { this.totalTapCount > 0 ? (Math.round(this.state.duration / this.totalTapCount)) : this.state.duration } s.</strong></p>
            </Card>
            <div className="buttons-container">
              <ButtonCard onClick={this.goNext}>
                Przejdź dalej
              </ButtonCard>
            </div>
          </Animation>
        </div>
      </Animation>
    )
  }
  //////////
  // endregion React functions
  //////////

  //////////
  // region Tapping events
  //////////
  ___tappingEvents___() {}

  tapped = (event) => {
    if (!this.tutorialActive()) {
      this.restartInactivity()
    }

    if (this.doubleTapping) {
      this.didDoubleTap(event)
    } else {
      this.didSingleTap(event)
    }

    event.stopPropagation()
    event.preventDefault()
  }

  didSingleTap = (event) => {
    this.doubleTapTimeout = setTimeout(this.doubleTapTimedOut, DOUBLE_TAP_INTERVAL_MS)
    this.doubleTapping = true

    this.tapCount++
    this.totalTapCount++

    this.animateTap(event)

    if (this.tutorialActive()) {
      if (this.state.tutorial.step === 0) {
        clearTimeout(this.doubleTapTimeout)
        this.doubleTapTimedOut()
        this.nextTutorialStep()
      }
    } else if (this.tapCount > 5) {
      this.errorMade()
    }
  }

  didDoubleTap = (event) => {
    clearTimeout(this.doubleTapTimeout)
    this.doubleTapping = false

    this.animateTap(event, true);

    if (this.tutorialActive()) {
      if (this.state.tutorial.step === 1) {
        this.nextTutorialStep();
      }
    } else if (this.tapCount !== 5) {
      this.errorMade()
    } else {
      this.tapCount = 0;
    }
  }

  doubleTapTimedOut = () => {
    this.doubleTapping = false

    if (!this.tutorialActive() && this.tapCount === 5) {
      this.errorMade()
    }
  }

  errorMade = () => {
    if (this.state.currentStep > this.STATES.BREATHING)
      return

    Sounds.short_bell.play()
    this.tapCount = 0
    this.restartInactivity()

    this.setState((prevState) => {
      return {
        points: prevState.points - 1,
      }
    })
  }

  restartInactivity = () => {
    clearTimeout(this.inactivityTimeout)
    this.inactivityTimeout = setTimeout(this.errorMade, INACTIVITY_INTERVAL_MS)
  }
  //////////
  // endregion Tapping events
  //////////

  //////////
  // region Animations
  //////////
  ___animations___() {}
  clearCirclePosition = () => {
    this.circlePosition = undefined
  }

  animateTap = (event, doubleTap = false) => {
    if (this.circlePosition === undefined) {
      this.circlePosition = {}
      let tapCircleBounds = this.tapCircleRef.current.getBoundingClientRect()
      this.circlePosition.x = tapCircleBounds.x //event.changedTouches ? event.changedTouches[0].clientX : event.clientX
      this.circlePosition.y = tapCircleBounds.y //event.changedTouches ? event.changedTouches[0].clientY : event.clientY
      this.circlePosition.width = tapCircleBounds.width
      this.circlePosition.height = tapCircleBounds.height
    }

    if (window.navigator.vibrate)
      window.navigator.vibrate(doubleTap ? 200 : 50);

    let animationDiv = document.createElement("div")
    animationDiv.className = "tap-feedback" + (doubleTap ? " double-tap" : '')
    animationDiv.style.left = pxString(this.circlePosition.x)
    animationDiv.style.top = pxString(this.circlePosition.y)
    animationDiv.style.width = pxString(this.circlePosition.width)
    animationDiv.style.height = pxString(this.circlePosition.height)

    let tapContainerElement = this.tapContainerRef.current
    tapContainerElement.append(animationDiv)

    Velocity(
      animationDiv,
      {
        scale: [0.95, 0],
        opacity: [0, 1]
      },
      {
        duration: DEFAULT_ANIMATION_SPEED,
      }
    )
  }
  //////////
  // endregion Animations
  //////////

  //////////
  // region State Management
  //////////
  ___stateManagement___() {}

  changeStep = (step) => {
    this.clearCirclePosition()

    this.setState({
      currentStep: step,
    })
  }

  startGame = (timeInMinutes) => {
    this.restartInactivity()

    this.changeStep(this.STATES.BREATHING)
    this.setState({
      duration: timeInMinutes * 60,
    })
  }

  startTutorial = () => {
    this.changeStep(this.STATES.TUTORIAL)
  }

  tutorialActive = () => {
    return this.state.currentStep === this.STATES.TUTORIAL
  }

  nextTutorialStep = () => {
    this.setState((prevState) => {
      prevState.tutorial.step++;

      return {
        tutorial: prevState.tutorial
      }
    })
  }

  timeRanOut = () => {
    clearTimeout(this.doubleTapTimeout)
    Sounds.long_bell.play()

    this.changeStep(this.STATES.SUMMARY)
  }

  goNext = () => {
    if (!this.state.visible) {
      return
    }

    setTimeout(this._goNext, DEFAULT_ANIMATION_SPEED)
    this.setState({
      currentStep: this.STATES.FINISHED,
    })
  }

  _goNext = () => {
    this.props.goNextAction(this.state.points)
  }
  //////////
  // endregion State Management
  //////////
}