import React, { Component } from 'react';
import {CSSTransitionGroup} from "react-transition-group";

import Card from "../components/Card";

import "./css/InformationScreen.css";

const ANIMATION_SPEED = 1000;

class InformationScreen extends Component {

  state = {
    visible: true,

    avatarPosition: 0,

    confusedVisible: false,
    enlightenedVisible: false,

    stepsCount: 4,
    showStep: {
      b1: false,
      1: false,
      2: false,
      3: false,
      b2: false,
      4: false,
      5: false,
      6: false,
    },
    showFinal: false,
    showNext: false,
  };

  componentDidMount() {
    // setTimeout(this._showStep.bind(this, 1), ANIMATION_SPEED);
  }

  _showStep = (id) => {
    // let stepsCount = Object.keys(this.state.showStep).length;

    if ([3, 6].indexOf(id) >= 0) {
      new Audio('/sounds/blop.mp3').play();
    }

    let newState = {};
    newState[id] = true;
    this.setState((prevState) =>{
      prevState.showStep[id] = true;

      return {
        showStep: prevState.showStep
      }
    });

    let showFinal = true;

    for (let stepId in this.state.showStep) {
      if (this.state.showStep.hasOwnProperty(stepId) && !this.state.showStep[stepId]) {
        showFinal = false;
        break;
      }
    }

    if (showFinal) {
      setTimeout(this._showFinal, 2000);
    }
  };

  _showFinal = () => {
    new Audio('/sounds/blop.mp3').play();
    this.setState({
      showFinal: true,
    });
    setTimeout(() => { new Audio('/sounds/blop.mp3').play() }, 3000);
    setTimeout(this._showNext, 6000);
  };

  _showNext = () => {
    this.setState({
      showNext: true,
    })
  };

  showSteps = (stepList) => {
    new Audio('/sounds/click.mp3').play();

    let timeout = 0;
    for (let step of stepList) {
      timeout += step.delay;
      setTimeout(this._showStep.bind(this, step.id), timeout);
    }
  };

  onNext = () => {
    new Audio('/sounds/click.mp3').play();

    this.setState({
      visible: false,
    });

    setTimeout(this.props.goNextAction, ANIMATION_SPEED);
  };

  render() {
    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
        <div className="InformationScreen">

          {/* Screen title */}
          <div className="pure-g title-container">
            <div className="pure-u-1-1">
              <Card classes="title">
                <p>
                  Prezentując innej osobie jakieś informacje, możesz to zrobić na dwa sposoby:
                </p>
              </Card>
            </div>
          </div>

          {/* Dry facts information */}
          <div className="pure-g">
            <div className="pure-u-3-5 image-container on-left">
              <Card classes={"full-image info " + (this.state.showStep[1] ? 'show' : '')}>
                <div className="image" style={{'backgroundImage': 'url(/images/information/book.jpg)'}}>
                  <div className={"description bad " + (this.state.showStep[2] ? 'show' : '') }>
                    {/*<h1>Możesz powiedzieć:</h1>*/}
                    <div className={"examples"}>
                      <div className={"pure-u-1-2"}>
                        <ul>
                          <li>To ma walory smakowe.</li>
                          <li>Malowniczy widok.</li>
                          <li>Ona ma irytujący śmiech.</li>
                          <li>30% ludzi ma kłopoty z kręgosłupem.</li>
                        </ul>
                      </div>
                      <div className={"pure-u-1-2"}>
                        <ul>
                          <li>To ma owalny kształt.</li>
                          <li>Temperatura spadnie do -18°C.</li>
                          <li>Unikać trudnych sytuacji.</li>
                          <li>Bagażnik ma 560 l pojemności.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="pure-u-2-5 image-container on-right">
              <Card classes={"full-image reaction " + (this.state.showStep[3] ? 'show' : '') }>
                <div className="image" style={{'backgroundImage': 'url(/images/information/yawn.jpg)'}}> </div>
              </Card>
            </div>
          </div>

          {/* Dry facts button */}
          <CSSTransitionGroup
            transitionName="fast"
            transitionAppear={true}
            transitionAppearTimeout={ANIMATION_SPEED}
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {!this.state.showStep['b1'] &&
              <div className="pure-g buttons-container information">
                <div className="pure-u-1-1 center show-information">
                  <Card classes="button"
                        onClick={this.showSteps.bind(this, [
                          {id: 'b1', delay: 0},
                          {id: 1, delay: 500},
                          {id: 2, delay: 2000},
                          {id: 3, delay: 5000},
                        ])}
                  >
                    <p>Poprzez podanie suchych faktów</p>
                  </Card>
                </div>
              </div>
            }
          </CSSTransitionGroup>

          {/* Descriptive speech information */}
          <div className="pure-g">
            <div className="pure-u-2-5 image-container on-left">
              <Card classes={"full-image reaction " + (this.state.showStep[6] ? 'show' : '') }>
                <div className="image" style={{'backgroundImage': 'url(/images/information/wow.jpg)'}}> </div>
              </Card>
            </div>
            <div className="pure-u-3-5 image-container on-right">
              <Card classes={"full-image info " + (this.state.showStep[4] ? 'show' : '')}>
                <div className="image" style={{'backgroundImage': 'url(/images/information/colorful.jpg)'}}>
                  <div className={"description good " + (this.state.showStep[5] ? 'show' : '') }>
                    {/*<h1>Możesz także powiedzieć:</h1>*/}
                    <div className={"examples"}>
                      <div className={"pure-u-1-2"}>
                        <ul>
                          <li>To jest pyszne.</li>
                          <li>Krajobraz zapierający dech w piersiach.</li>
                          <li>Jej śmiech jest jak skrzypienie nienaoliwionych drzwi.</li>
                          <li>Co trzeci z nas cierpi w powodu bólu kręgosłupa.</li>
                        </ul>
                      </div>
                      <div className={"pure-u-1-2"}>
                        <ul>
                          <li>To przypomina kształtem jajko.</li>
                          <li>Temperatura będzie jak w zamrażalce.</li>
                          <li>Unikać sytuacji podnoszących ciśnienie.</li>
                          <li>Bagażnik w który zapakuje się 5-osobowa rodzina.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>

          {/* Descriptive speech button */}
          <CSSTransitionGroup
            transitionName="fast"
            transitionAppear={true}
            transitionAppearTimeout={ANIMATION_SPEED}
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {!this.state.showStep['b2'] &&
              <div className="pure-g buttons-container information">
                <div className="pure-u-1-1 center show-information">
                  <Card classes="button"
                        onClick={this.showSteps.bind(this, [
                          {id: 'b2', delay: 0},
                          {id: 4, delay: 500},
                          {id: 5, delay: 2000},
                          {id: 6, delay: 5000},
                        ])}
                  >
                    <p>Poprzez mówienie obrazowe</p>
                  </Card>
                </div>
              </div>
            }
          </CSSTransitionGroup>

          {/* Descriptive speech information */}
          <CSSTransitionGroup
            transitionName="fast"
            transitionAppear={true}
            transitionAppearTimeout={ANIMATION_SPEED}
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {this.state.showFinal &&
              <div className="pure-g">
                <div className="pure-u-1-1">
                  <Card classes="word-painting-description">
                    <p>
                      Suche fakty informują.
                    </p>
                    <p className={'accent'}>
                      Mówienie obrazowe informuje i buduje zainteresowanie!
                    </p>
                  </Card>
                </div>
              </div>
            }
          </CSSTransitionGroup>

          {/* Next screen button */}
          <div className="pure-g buttons-container">
            <div className="pure-u-1-1 center">
              <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={ANIMATION_SPEED}
                transitionEnter={true}
                transitionEnterTimeout={ANIMATION_SPEED}
                transitionLeave={true}
                transitionLeaveTimeout={ANIMATION_SPEED}>
                {this.state.showNext &&
                  <Card classes="next-button" onClick={this.onNext}>
                    <p>Przejdź dalej</p>
                  </Card>
                }
              </CSSTransitionGroup>
            </div>
          </div>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}

export default InformationScreen;
