import { Howl } from 'howler'


import importFromContext from "../ImportFromDirectory";

const MP3Files = importFromContext(require.context("./mp3", false, /\.(mp3)$/));

class Sound {
  constructor(sources) {
    this.sound = new Howl({
      src: sources
    })
  }

  play() {
    this.sound.play(undefined, undefined)
  }
}

export default class Sounds {
  static error = new Sound([MP3Files['error.mp3']]);
  static success = new Sound([MP3Files['success.mp3']]);
  static click = new Sound([MP3Files['click.mp3']]);

  static short_bell = new Sound([MP3Files['short_bell.mp3']]);
  static long_bell = new Sound([MP3Files['long_bell.mp3']]);

  static time_out = new Sound([MP3Files['time_out.mp3']]);
}

