import React, {Component} from "react";
import PropTypes from 'prop-types';

import './Overlay.scss';

export default class Overlay extends Component {
  static propTypes = {
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    zIndex: 100,
  };

  render () {
    return (
      <div className="Overlay" style={{zIndex: this.props.zIndex}} />
    )
  }
}