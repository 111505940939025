import React, { Component } from 'react';
import PropTypes from 'prop-types'

import './css/PointsBar.scss';
import Card from "./Card";

class PointsBar extends Component {
  secondsTimeout;

  static propTypes = {
    'hidePoints': PropTypes.bool,
    'points': PropTypes.number,
    'maxPoints': PropTypes.number,
    'negativePointsAllowed': PropTypes.bool,

    'hideClock': PropTypes.bool,
    'timeLimit': PropTypes.number,
    'time': PropTypes.number,
    'clockRunning': PropTypes.bool,
    'clockWarningSeconds': PropTypes.number,
    'clockId': PropTypes.number,

    'onSecondPassed': PropTypes.func,
    'onTimeRanOut': PropTypes.func,

    'instruction': PropTypes.string,
  };

  static defaultProps = {
    'hidePoints': false,
    'points': 0,
    negativePointsAllowed: false,

    'hideClock': false,
    'timeLimit': 0,
    'time': null,
    'clockRunning': false,
    'onTimeRanOut': () => {},
    'onSecondPassed': () => {},
    'clockWarningTime': -1,
    'clockId': 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      time: props.timeLimit,
    };


    if (this.props.clockRunning) {
      this.startSecondsTimer();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clockId !== this.props.clockId) {
      if (this.props.clockRunning) {
        this.startSecondsTimer()
      }

      this.setState({
        time: this.props.timeLimit
      })
    }
  }

  startSecondsTimer = () => {
    clearTimeout(this.secondsTimeout);
    this.secondsTimeout = setTimeout(this.secondPassed, 1000);
  }

  secondPassed = () => {
    if (this.props.clockRunning) {
      if (this.state.time > 0) {
        this.props.onSecondPassed(this.state.time - 1)
        this.setState((prevState) => {
          return {
            time: prevState.time - 1,
          }
        });
        this.startSecondsTimer();
      } else {
        this.props.onTimeRanOut();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clockRunning && !this.props.clockRunning) {
      this.startSecondsTimer();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.secondsTimeout);
  }

  render() {
    let time = this.props.time ? this.props.time : this.state.time;
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;

    return (
      <div className="PointsBar right">
        {this.props.instruction &&
        <Card classes='instruction'>
          <div>
            <p>{this.props.instruction}</p>
          </div>
        </Card>
        }
        {!this.props.hidePoints &&
        <Card>
          <div className="pure-u-1-4"><i className="fas fa-trophy" /></div>
          <div className="pure-u-3-4">{this.getPoints()}{this.props.maxPoints ?  ' / ' + this.props.maxPoints : ''}</div>
        </Card>}
        {!this.props.hideClock &&
        <Card className={'clock' + (minutes === 0 && seconds <= this.props.clockWarningSeconds ? ' warning' : '')}>
          <div className="pure-u-1-4"><i className="fas fa-clock" /></div>
          <div className="pure-u-3-4">{minutes < 10 ? '0' : ''}{minutes}:{seconds < 10 ? '0' : ''}{seconds}</div>
        </Card>}
      </div>
    );
  }

  getPoints = () => {
    if (this.props.negativePointsAllowed) {
      return this.props.points
    } else {
      return this.props.points < 0 ? 0 : this.props.points
    }
  }
}



export default PointsBar;
