import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { detect } from 'detect-browser';

import './css/MessageScreen.scss';
import Card from "../components/Card";
import {CSSTransitionGroup} from "react-transition-group";
import Button from "../components/Button/Button";

const ANIMATION_SPEED = 1000;

export default class MessageScreen extends Component {
  static propTypes = {
    image: PropTypes.string,
    imageFormat: PropTypes.string,
    header: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    html: PropTypes.bool,
    bigImage: PropTypes.bool,
    buttonText: PropTypes.string,
    browserWarning: PropTypes.bool,
    forVisa: PropTypes.bool,

    nextEnabled: PropTypes.bool,
    refreshEnabled: PropTypes.bool,

    goNextAction: PropTypes.func,
  };

  static defaultProps = {
    imageFormat: 'jpg',
    html: false,
    bigImage: false,
    forVisa: false,
    browserWarning: false,
    nextEnabled: true,
    refreshEnabled: false,
    buttonText: 'Przejdź do ćwiczenia',

    goNextAction: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };

    let detectedBrowser = detect();
    this.browser = detectedBrowser ? detectedBrowser.name : 'Unknown';

  }

  goNext = () => {
    new Audio('/sounds/click.mp3').play();
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  };

  _goNext = () => {
    this.props.goNextAction();
  };

  _reload = () => {
    window.location.reload();
  }

  render() {
    return (
      <CSSTransitionGroup
      transitionName="example"
      transitionAppear={true}
      transitionAppearTimeout={ANIMATION_SPEED}
      transitionEnter={true}
      transitionEnterTimeout={ANIMATION_SPEED}
      transitionLeave={true}
      transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
          <div>
            <div className="OldMessageScreen">
              <Card className={'message' + (this.props.bigImage ? ' big-image' : '')}>
                {!this.props.bigImage &&
                <div className={'image'}>
                  <img src={'images/message/' + this.props.image + '.' + this.props.imageFormat} alt={'Message'}/>
                </div>
                }
                <div className={'content'}>
                  <h1>{ this.props.header }</h1>
                  {this.props.content && !this.props.html &&
                  <p>{ this.props.content }</p>
                  }
                  {this.props.content && this.props.html &&
                  <div dangerouslySetInnerHTML={{__html: this.props.content}} />
                  }
                </div>
                {this.props.bigImage &&
                <div className={'image'}>
                  <img src={'images/message/' + this.props.image + '.' + this.props.imageFormat} alt={'Message'}/>
                </div>
                }
              </Card>
            </div>
            {this.props.nextEnabled &&
            <div className="pure-g buttons-container">
              <div className="pure-u-1-1 center">
                <Card classes="next-button" onClick={this.goNext}>
                  <p>{this.props.buttonText}</p>
                </Card>
              </div>
            </div>
            }
            {this.props.refreshEnabled &&

                <Button onClick={this._reload}>
                  <p>Odśwież aplikację</p>
                </Button>

            }
          </div>
        }
      </CSSTransitionGroup>
    );
  }
}
