import AnimatedObjectFactory from "../../../animations/AnimatedObjectFactory/AnimatedObjectFactory";

export const ANIMATION_POSES = {
  start: 'start',
  paused: 'paused',
  moving: 'moving',
  feedback: 'feedback',
};

const BubbleDiv = AnimatedObjectFactory.div(
  {
    [ANIMATION_POSES.start]: {
      x: ({startPosition}) => startPosition.x,
      y: ({startPosition}) => startPosition.y,
      opacity: 0,

      transition: {
        duration: 0,
      }
    },
    [ANIMATION_POSES.paused]: {
      x: 0,
      y: 0,
      opacity: 0,
      transition: {
        opacity: ({from, to}) => ({
          type: 'keyframes',
          duration: 2000,
          values: [from, from, to],
          times: [0, 0.8, 1],
        }),
        default: ({from}) => {
          return {
            to: from,
          }
        }
      }
    },
    [ANIMATION_POSES.moving]: {
      x: ({finishPosition}) => finishPosition.x,
      y: ({finishPosition}) => finishPosition.y,
      opacity: 0,
      transition: ({duration}) => ({
        // duration: duration,
        x: { type: 'tween', duration: duration, ease: 'linear' },
        y: { type: 'tween', duration: duration, ease: 'linear' },
        opacity: ({from, to}) => ({
          type: 'keyframes',
          duration: duration,
          values: [from, 1, 1, to],
          times: [0, 0.1, 0.9, 1],
        }),
      }),
    },
    [ANIMATION_POSES.feedback]: {
      transition: {
        duration: 5000,
      }
    },

    props: {
      startPosition: {x: 0, y: 0},
      finishPosition: {x: 0, y: 0},
      duration: 5000,
    }
  }
);

export default BubbleDiv;