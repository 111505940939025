import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Container.scss';

export default class Container extends Component {
  static propTypes = {
    className: PropTypes.string,
    autoHeight: PropTypes.bool,
  };

  static defaultProps = {
    autoHeight: false,
  };

  render() {
    return (
      <div className={classNames("Container", {"auto-height": this.props.autoHeight}, this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}