import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import Velocity from "velocity-animate"

import Card from "../../components/Card"

import PointsBar from "../../components/PointsBar/PointsBar"
import Animation from "../../components/Animation"
import PlayArea from "../../lib/PlayArea"
import RiseFallCard from "./subcomponents/RiseFallCard";
import {emString} from "../../utils/styling"
import Timer from "../../utils/Timer";
import Sounds from "../../lib/Sounds";
import OverlayMessage from "../../components/OverlayMessage";

import "./RiseFallExercise.scss"
import Button from "../../components/Button/Button";
import InstructionCard from "../../components/InstructionCard/InstructionCard";
import FeedbackCard from "../../components/FeedbackCard/FeedbackCard";
import AnimatedElement from "../../components/AnimatedElement/AnimatedElement";

import cloud1Img from './img/cloud-1.svg';
import cloud2Img from './img/cloud-2.svg';
import cloud3Img from './img/cloud-3.svg';

// export const ANSWERS_HEIGHT_HORIZONTAL_EMS = 55;
// export const ANSWERS_HEIGHT_VERTICAL_EMS = 55;
export const ANSWERS_HEIGHT_HORIZONTAL_EMS = 95;
export const ANSWERS_HEIGHT_VERTICAL_EMS = 110;
export const ANSWER_WIDTH_EMS = 26;
export const ANSWER_HEIGHT_EMS = 18;

export const START_POSITIONS_HORIZONTAL= [0, (PlayArea.widthInEms() - ANSWER_WIDTH_EMS) / 2, PlayArea.widthInEms() - ANSWER_WIDTH_EMS];
export const START_POSITIONS_VERTICAL= [0, PlayArea.widthInEms() - ANSWER_WIDTH_EMS];

export const MOVEMENT_HORIZONTAL = ANSWERS_HEIGHT_HORIZONTAL_EMS - ANSWER_HEIGHT_EMS;
export const MOVEMENT_VERTICAL = ANSWERS_HEIGHT_VERTICAL_EMS - ANSWER_HEIGHT_EMS;

export const ANIMATION_SPEED = 1000;
export const FLIGHT_DURATION = 17000;
// export const FLIGHT_DURATION = 30000;

const ANSWER_POP_MARGIN = 1000;
const FEEDBACK_DURATION = 6000;
const DEFAULT_TIME_LIMIT_S = 90;

const TUTORIAL_MESSAGES = [
  'W tym ćwiczeniu musisz wybrać najlepszy sposób na odparcie wyświetlonego zastrzeżenia. Kliknij na podświetlone sformułowanie.',
  'To był przykład złego wyboru. Musisz szukać dalej właściwej odpowiedzi. Spróbuj kliknąć w kolejne podświetlone sformułowanie.',
  'To był dobry wybór. Po takiej odpowiedzi przejdziesz do następnego zastrzeżenia. Kliknij na \'Przejdź dalej\' aby spróbować swoich sił samemu. Powodzenia! :)',
];

class RiseFallExercise extends Component {
  nextAnswerTimeout
  nextQuestionTimeout
  hideFeedbackTimeout
  visibleAnswersCount = 0
  lastAnswerPopDate = new Date()
  halfTime = false
  flightDuration = FLIGHT_DURATION
  timeBetweenAnswers = FLIGHT_DURATION / 3
  
  chosenAnswerIds = [];
  
  startPositions = [0]

  static propTypes = {
    instruction: PropTypes.string,
    questions: PropTypes.array,
    answerTypes: PropTypes.object,

    tutorial: PropTypes.bool,
    tutorialMessages: PropTypes.array,

    goNextAction: PropTypes.func,
  }

  static defaultProps = {
    tutorial: false,
    tutorialMessages: TUTORIAL_MESSAGES,
  }

  lastAnswerPosition;

  constructor(props) {
    super(props)

    let maxPoints = 0

    for (let question of props.questions) {
      maxPoints += question.answers.length
    }

    const timeLimit = props.parameters["timePerQuestionSeconds"];

    this.state = {
      visible: true,

      started: false,
      clockRunning: false,
      clockId: 0,
      finished: false,
      timeRanOut: false,
      points: 0,
      playing: true,

      currentQuestionIndex: 0,
      currentAnswerIndex: 0,
      answers: [],

      timeLimit: timeLimit ? timeLimit : DEFAULT_TIME_LIMIT_S,

      feedback: {
        show: false,
        type: 'success',
        content: '',
      },

      currentPoints: 0,
      maxPoints: maxPoints,

      tutorialStep: 0,

      changed: 0,
    }

    this.state.answers = this._prepareAnswersForCurrentQuestion()
    this.state.currentPoints = this._maxPointsForCurrentQuestion()

    this.cloudRefs = {
      1: React.createRef(),
      2: React.createRef(),
      3: React.createRef(),
    }

    if (props.tutorial) {
      this.flightDuration = 25000;
      this.timeBetweenAnswers = this.flightDuration / 3;
    }

    this.goNext = this.goNext.bind(this)
    this._goNext = this._goNext.bind(this)
  }

  componentDidMount()  {
    this.calculateStartPositions();
    window.addEventListener("resize", this.calculateStartPositions);
  }
  
  calculateStartPositions = () => {
    if (PlayArea.isHorizontal()) {
      this.startPositions = [0, (PlayArea.widthInEms() - ANSWER_WIDTH_EMS) / 2, PlayArea.widthInEms() - ANSWER_WIDTH_EMS];
    } else {
      this.startPositions = [0, PlayArea.widthInEms() - ANSWER_WIDTH_EMS];
    }
  }

  answerChosen = (answer, index) => {
    if (!this.state.playing || !answer.visible || !answer.active) {
      return
    }

    if (this.props.tutorial) {
      this.setState((prevState) => {
        return {
          tutorialStep: prevState.tutorialStep + 1
        }
      })
    }

    let pointChange = 0
    let currentPoints = this.state.currentPoints
    let feedbackType
    
    this.chosenAnswerIds.push(answer.id);

    if (answer.correct) {
      if (answer.parking) {
        currentPoints--
        pointChange = 1
      } else {
        pointChange = this.halfTime ? _.ceil(currentPoints / 2) : currentPoints
      }
      feedbackType = 'success'
      Sounds.success.play()
    } else {
      currentPoints--
      feedbackType = 'failure'
      Sounds.error.play()
    }

    this.setState((prevState) => {
      let feedbackContent = answer.feedback

      let points = prevState.points + pointChange
      let timeout = new Timer(this.hideAnswer.bind(this, index), this.flightDuration / 10)

      prevState.answers[index].active = false
      prevState.answers[index].showFeedback = true
      prevState.answers[index].moving = false
      prevState.answers[index].timeout = timeout

      if (answer.correct && !answer.parking) {
        for (let answer of prevState.answers) {
          answer.visible = false
          answer.active = false
        }

        this.nextQuestionTimeout = setTimeout(this.nextQuestion, FEEDBACK_DURATION)
      } else {
        let duration = FEEDBACK_DURATION + ANSWER_POP_MARGIN
        if (this.visibleAnswersCount > 1) {
          duration = FEEDBACK_DURATION + ANSWER_POP_MARGIN + this.timeBetweenAnswers - (new Date() - this.lastAnswerPopDate)
        }

        this.showNextAnswerIn(duration)
      }

      this.hideFeedbackTimeout = setTimeout(this.hideFeedback, FEEDBACK_DURATION)

      return {
        points,
        currentPoints,
        answers: prevState.answers,
        feedback: {
          show: true,
          type: feedbackType,
          content: feedbackContent,
        }
      }
    })
  }

  secondPassed = (timeLeft) => {
    const {timeLimit} = this.state
    this.halfTime = timeLeft < timeLimit / 2
  }

  timeRanOut = () => {
    Sounds.error.play();

    this.setState((prevState) => {
      for (let answer of prevState.answers) {
        answer.visible = false
        answer.active = false
      }

      this.nextQuestionTimeout = setTimeout(this.nextQuestion, 2 * ANIMATION_SPEED)

      return {
        answers: prevState.answers,
      }
    })
  }

  hideFeedback = () => {
    this.setState((prevState) => {
      prevState.feedback.show = false
      return {
        feedback: prevState.feedback
      }
    })
  }

  nextQuestion = () => {
    this.visibleAnswersCount = 0

    let newQuestionIndex = this.state.currentQuestionIndex + 1;
    if (newQuestionIndex < this.props.questions.length) {
      this.showNextAnswerIn(ANIMATION_SPEED + ANSWER_POP_MARGIN)
      this.setState({
        currentQuestionIndex: newQuestionIndex
      })

      this.halfTime = false

      this.setState((prevState) => {
        return {
          clockId: prevState.clockId + 1,
          currentAnswerIndex: 0,
          answers: this._prepareAnswersForCurrentQuestion(),
          currentPoints:this._maxPointsForCurrentQuestion(),
        }
      })
    } else {
      this.setState({
        playing: false,
        finished: true,
        clockRunning: false,
      })
    }
  }

  showNextAnswerIn = (timeoutMS) => {
    clearTimeout(this.nextAnswerTimeout)
    this.nextAnswerTimeout = setTimeout(this.popNextAnswer, timeoutMS)
  }

  popNextAnswer = () => {
    if (this.state.playing) {
      let newIndex = this.state.currentAnswerIndex
      let answers = this.state.answers

      while (answers[newIndex].visible || !answers[newIndex].active) {
        newIndex = this._nextAnswerIndex(newIndex)

        if (newIndex === this.state.currentAnswerIndex) {
          // No inactive and not visible answers
          break
        }
      }

      if (!answers[newIndex].visible && answers[newIndex].active) {
        if (answers[newIndex].timer) {
          clearTimeout(answers[newIndex].timer)
        }

        this.setState((prevState) => {
          let timeout = new Timer(this.hideAnswer.bind(this, newIndex), this.flightDuration - this.flightDuration / 10)

          prevState.answers[newIndex].visible = true
          prevState.answers[newIndex].moving = true
          prevState.answers[newIndex].timeout = timeout
          prevState.answers[newIndex].left = this._getNextPosition()
          prevState.answers[newIndex].falling = this.halfTime

          return {
            answers: prevState.answers,
            currentAnswerIndex: this._nextAnswerIndex(prevState.currentAnswerIndex),
          }
        })
      }

      this.visibleAnswersCount++;
      this.lastAnswerPopDate = new Date()
      this.showNextAnswerIn(this.timeBetweenAnswers)
    }
  }

  hideAnswer = (answerIndex) => {
    this.visibleAnswersCount--;
    this.setState((prevState) => {
      let timeout =  new Timer(this.stopMovingAnswer.bind(this, answerIndex), this.flightDuration / 10)
      prevState.answers[answerIndex].visible = false
      prevState.answers[answerIndex].timeout = timeout

      return {
        answers : prevState.answers,
      }
    })
  }

  stopMovingAnswer = (answerIndex) => {
    this.setState((prevState) => {
      prevState.answers[answerIndex].moving = false

      return {
        answers : prevState.answers,
        timer: null,
      }
    })
  }

  _nextAnswerIndex = (oldIndex) => {
    let newIndex = oldIndex + 1

    if (newIndex >= this.state.answers.length) {
      newIndex = 0
    }

    return newIndex
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateStartPositions);
    clearTimeout(this.nextAnswerTimeout)
  }

  startGame = () => {
    if (!this.state.started) {
      this.setState({
        started: true,
        clockRunning: !this.props.tutorial,
      })
      Sounds.click.play();
      
      this.showNextAnswerIn(ANIMATION_SPEED * 2)
      setTimeout(this._startClouds.bind(this), 1000)
    }
  }

  _startClouds = () => {
    Velocity(
      this.cloudRefs[1].current, {
        translateX: [emString(PlayArea.widthInEms() + 70), 0],
      }, {
        duration: "80000",
        loop: true,
      }
    )
    Velocity(
      this.cloudRefs[2].current, {
        translateX: [emString(PlayArea.widthInEms() + 60), 0],
      }, {
        duration: "80000",
        loop: true,
      }
    )
    Velocity(
      this.cloudRefs[3].current, {
        translateX: [emString(PlayArea.widthInEms() + 50), 0],
      }, {
        duration: "80000",
        loop: true,
      }
    )
  }

  getCurrentQuestion = () => {
    return this.props.questions[this.state.currentQuestionIndex]
  }

  _prepareAnswersForCurrentQuestion = () => {
    let currentAnswers = this.getCurrentQuestion().answers
    let answers = _.clone(currentAnswers)
    if (!this.props.tutorial) {
      answers = _.shuffle(answers)
    }

    return answers.map((answer, index) => {
      return {
        id: answer.id,
        
        visible: false,
        active: true,
        moving: false,
        showFeedback: false,

        tutorialStepVisible: index,

        content: answer.content,
        correct: answer.correct,
        feedback: answer.parameters.feedback,
        parking: answer.parameters.parking,

        left: 0,
        falling: false,
        timeout: null,
      }
    })
  }

  _currentQuestionString = () => {
    return this.getCurrentQuestion().content
  }

  _maxPointsForCurrentQuestion = () => {
    return this.getCurrentQuestion().answers.length
  }

  goNext() {
    if (!this.state.visible) {
      return
    }

    Sounds.click.play();
    this.setState({
      visible: false,
    })
    setTimeout(this._goNext, ANIMATION_SPEED)
  }

  _goNext() {
    if (this.props.tutorial)
      this.props.goNextAction(0)
    else
      this.props.goNextAction({
        points: this.state.points,
        other: {
          chosenAnswerIds: this.chosenAnswerIds
        },
      })
  }

  _getAnswersContainerHeight = () => {
    if (PlayArea.isVertical()) {
      if (this.props.tutorial) {
        return Math.ceil(ANSWERS_HEIGHT_VERTICAL_EMS * 1.2)
      } else {
        return ANSWERS_HEIGHT_VERTICAL_EMS
      }
    } else {
      if (this.props.tutorial) {
        return Math.ceil(ANSWERS_HEIGHT_HORIZONTAL_EMS * 1.2)
      } else {
        return ANSWERS_HEIGHT_HORIZONTAL_EMS
      }
    }
  }

  _getNextPosition = () => {
    let newPosition;

    do {
      newPosition = _.sample(this.startPositions)
    } while (newPosition === this.lastAnswerPosition)

    this.lastAnswerPosition = newPosition
    return newPosition
  }

  render() {
    const {timeLimit} = this.state;

    let answers = this.state.answers.map((answer, i) => {
      let style = {
        bottom: 0,
        left: emString(answer.left),

        opacity: answer.visible ? "1" : "0",
        zIndex: answer.visible ? (answer.tutorialStepVisible === this.state.tutorialStep ? 10000 : "3") : "0",

        transition: `opacity ${this.flightDuration / 10}ms ease-in-out`,
      }

      let shouldMove = answer.moving && !this.state.feedback.show

      return <RiseFallCard
        answer={answer}
        clickCallback={this.answerChosen}
        moving={shouldMove}

        style={style}

        index={i}
        key={this.state.currentQuestionIndex + '-' + i}
      />
    });

    let answersContainerStyle = {
      height: emString(this._getAnswersContainerHeight()),
    };

    return (
      <Animation type="fade" active={this.state.visible}>
        <div className={'RiseFallExercise' + (this.props.tutorial ? ' tutorial' : '')}>
          <OverlayMessage
            visible={this.props.tutorial}
            messages={this.props.tutorialMessages} messageId={this.state.tutorialStep} />
          <Animation type="fade" active={this.state.started}>
            <div className={'clouds-container'}>
              <img className={'cloud'} id={'cloud-1'} ref={this.cloudRefs[1]} src={cloud1Img} alt="Cloud" />
              <img className={'cloud'} id={'cloud-2'} ref={this.cloudRefs[2]} src={cloud2Img} alt="Cloud" />
              <img className={'cloud'} id={'cloud-3'} ref={this.cloudRefs[3]} src={cloud3Img} alt="Cloud" />
            </div>
          </Animation>
          <PointsBar
            points={this.state.points} maxPoints={this.state.maxPoints} hidePoints={this.props.tutorial}
            timeLimit={timeLimit} clockRunning={this.state.clockRunning && !this.state.feedback.show} clockId={this.state.clockId}
            hideClock={this.props.tutorial}
            onTimeRanOut={this.timeRanOut} clockWarningSeconds={10}
            onSecondPassed={this.secondPassed}
            instruction={this.props.instruction}
          />
          <AnimatedElement fullSize visible={!this.state.started} animation={AnimatedElement.AnimationTypes.popOut}>
            <Button onClick={this.startGame} big>
              Rozpocznij zadanie!
            </Button>
          </AnimatedElement>
          <AnimatedElement visible={this.state.started && !this.state.finished}>
            <Animation type="fade" active={this.state.started} key="question">
              <InstructionCard
                countType="Zastrzeżenie" countCurrent={this.state.currentQuestionIndex + 1} countMax={this.props.questions.length}
                mainText={this._currentQuestionString()}
              />
            </Animation>
            <FeedbackCard key="feedback" visible={this.state.feedback.show}
              content={this.state.feedback.content} successful={this.state.feedback.type === 'success'}/>
            <div className="answers-container" style={answersContainerStyle}  key="answers">
              <Animation type="fade" active={this.state.timeRanOut}>
                <Card classes='timeFinished pure-u-1-1'>
                  <h1>Koniec czasu!</h1>
                </Card>
              </Animation>
              {answers}
            </div>
          </AnimatedElement>
          <AnimatedElement fullSize visible={this.state.finished} animation={AnimatedElement.AnimationTypes.popOut}>
            <Button onClick={this.goNext} big>
              Przejdź dalej
            </Button>
          </AnimatedElement>
        </div>
      </Animation>
    )
  }
}

export default RiseFallExercise
