import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './css/SprintBar.css';
// import Card from "./Card";

class SprintBar extends Component {
  static propTypes = {
    points: PropTypes.number,
    maxPoints: PropTypes.number,

    level: PropTypes.number,
    maxLevels: PropTypes.number,

    helpClick: PropTypes.func,
    skipClick: PropTypes.func,
  };

  static defaultProps = {
    points: 0,
    maxPoints: 100,

    level: 1,
    maxLevels: 1,
  };

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <div className="SprintBar">
        <div className="logo">
          <img src="/images/logo-blue.png" alt="Sprintech Learning logo" />
        </div>
        <div className="container" style={{'width': this.props.width}}>
          <div className="pure-u-2-5 left">Wynik sprintu: <strong>{this.props.points} na {this.props.maxPoints}</strong></div>
          <div className="pure-u-1-5 center"><strong>{this.props.level} z {this.props.maxLevels}</strong></div>
          <div className="pure-u-2-5 right">{/*<Card classes="help-button" onClick={this.props.skipClick}><i className="fas fa-times-circle" /> Zakończ</Card><Card classes="help-button" onClick={this.props.helpClick}><i className="fas fa-question" /> Pomoc</Card>*/}</div>
        </div>
      </div>
    );
  }
}

export default SprintBar;
