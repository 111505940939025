import mojs from "mo-js";

export default class Animations {
  static bursts = {}
  static resizeInitialized = false
  static mainInitialized = false

  static newBurst(name, firstColor, secondColor) {
    if (!Animations.resizeInitialized) {
      this._listenForResize()
    }

    let burst = new mojs.Burst({
      left: 0, top: 0,
      radius:   { 0: 100 },
      angle:    'rand(0, 360)',
      count:    10,
      timeline: { delay: 0 },
      isShowEnd: false,
      children: {
        shape: 'line',
        radius:       10,
        strokeLinecap: 'round',
        stroke: {firstColor: secondColor},
        strokeDashoffset: { 0 : '100%' },
        strokeWidth: 5,
        fill:         {firstColor: secondColor},
        scale:        { 1: 0, easing: 'quad.in' },
        pathScale:    [ .8, null ],
        degreeShift:  [ 13, null ],
        duration:     [ 700, 700 ],
        easing:       'quint.out'
      },
    })
    // onStart: () => {
    //   burst.el.style.zIndex = 100;
    // },
    // onComplete: () => {
    //   burst.el.style.zIndex = -1;
    // },
    burst.el.style.zIndex = 100;

    Animations._recalculateSize(burst)
    Animations.bursts[name] = burst

    return burst
  }

  static playBurst(name, position) {
    if (!Animations.mainInitialized) {
      Animations._recalculateSize(Animations.bursts[name])
    }

    Animations.bursts[name].tune({
      x: position[0],
      y: position[1],
    }).replay();
  }

  static _recalculateSizes() {
    let size = Animations._getSizeForCurrentResolution()

    // debugger;
    for (let burst of Object.values(Animations.bursts)) {
      Animations._recalculateSize(burst, size)
    }
  };

  static _recalculateSize(burst, size) {
    if (!size) {
      size = Animations._getSizeForCurrentResolution()
    }

    burst.tune({
      radius: { 0: size * 5 },
      children: {
        radius: size,
        strokeWidth: size / 2,
      }
    })
  }

  static _getSizeForCurrentResolution() {
    let main = document.getElementById("main")
    if (!main) {
      return window.innerWidth / 50
    } else {
      Animations.mainInitialized = true
      return parseFloat(getComputedStyle(main).fontSize);
    }
  }

  static _listenForResize() {
    window.addEventListener("resize", this._recalculateSizes);
  }

  static reset() {
    this.bursts = {}
    window.removeEventListener("resize", this._recalculateSizes)
  }
}