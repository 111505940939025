import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AnimatedElement from "../../components/AnimatedElement/AnimatedElement";
import PointsBar from "../../components/PointsBar/PointsBar";
import InstructionCard from "../../components/InstructionCard/InstructionCard";
import MyComponent from "../../base/MyComponent";
import Button from "../../components/Button/Button";
import Sounds from "../../lib/Sounds";

import RevealExerciseCard from "./subcomponents/RevealExerciseCard";
import './RevealExercise.scss';

const STATES = {
  STARTING: 0,
  QUESTION_APPEARING: 1,
  QUESTION_ANSWERING: 2,
  QUESTION_ANSWERED: 3,
  QUESTION_HIDING: 4,
  QUESTION_HIDDEN: 5,
  ALL_QUESTIONS_ANSWERED: 6,
  FINISHED: 7,
};

const DEFAULT_TIME_LIMIT = 120;
const ANIMATION_SPEED = 1000;

const POINTS_FOR_CORRECT_ANSWER = 2;
const POINTS_FOR_INCORRECT_ANSWER = -2;

export default class RevealExercise extends MyComponent {
  chosenAnswerIds = [];

  static propTypes = {
    questions: PropTypes.array,
    parameters: PropTypes.shape({

    }),

    onFinish: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const timeLimit = props.parameters["timePerQuestionSeconds"];

    this.state = {
      points: 0,
      maxPoints: RevealExercise.maxPoints(props.questions),

      timeLimit: timeLimit || DEFAULT_TIME_LIMIT,

      correctAnswersLeftByQuestion: RevealExercise.countCorrectAnswersByQuestion(props.questions),
      lastClickedAnswerIndex: undefined,
    };

    MyComponent.myConstructor(props, this.state, STATES.STARTING);
    MyComponent.shuffleAnswers(this.state);
    MyComponent.processAnswers(this.state, {
      isActive: true,
      wasActivated: false,
      isBlurred: true,
    });
  }

  static maxPoints(questions) {
    let maxPoints = 0;

    for (let question of questions) {
      for (let answer of question.answers) {
        if (answer.correct) {
          maxPoints += 2
        }
      }
    }

    return maxPoints;
  }

  static countCorrectAnswersByQuestion(questions) {
    let correctAnswersByQuestion = [];
    let currentIndex = 0;

    for (let question of questions) {
      correctAnswersByQuestion[currentIndex] = 0;
      for (let answer of question.answers) {
        if (answer.correct) {
          correctAnswersByQuestion[currentIndex] += 1;
        }
      }
      currentIndex++;
    }

    return correctAnswersByQuestion;
  }

  render() {
    const {questions, questionIndex, timeLimit} = this.state;

    return (
      <AnimatedElement className="RevealExercise" visible={!this.inState(STATES.FINISHED)}>
        <PointsBar
          instruction="Kliknij raz, by odkryć kafel, dwa razy by wybrać odpowiedź."
          points={this.state.points} maxPoints={this.state.maxPoints}
          timeLimit={timeLimit} clockRunning={this.inState(STATES.QUESTION_ANSWERING)} clockId={this.state.questionIndex}
          onTimeRanOut={this.timeRanOut} clockWarningSeconds={Math.ceil(timeLimit / 4)}
        />
        <AnimatedElement fullSize visible={this.inState(STATES.STARTING)} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={500}>
          <Button onClick={this.showQuestion} big>
            Rozpocznij
          </Button>
        </AnimatedElement>
        <InstructionCard visible={this.inStates([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
          countType="Pytanie" countCurrent={questionIndex + 1} countMax={this.state.questions.length}
          mainText={questions[questionIndex].content}
        />
        <AnimatedElement className={classNames("answers", "scrollable", {"inactive": !this.inState(STATES.QUESTION_ANSWERING)})}
          visible={this.inStates([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}>
          { this.renderAnswers() }
        </AnimatedElement>
        <AnimatedElement fullSize visible={this.inStates(STATES.QUESTION_ANSWERED)} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={2000}>
          <Button onClick={this.continueGame} big>
            Przejdź dalej
          </Button>
        </AnimatedElement>
      </AnimatedElement>
    );
  }

  timeRanOut = () => {
    this.setCurrentState(STATES.QUESTION_ANSWERED);
  };

  showQuestion = () => {
    this.setCurrentStateSequence([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING], ANIMATION_SPEED);
  };

  renderAnswers = () => {
    const {questions, questionIndex} = this.state;

    return questions[questionIndex].answers.map((answer, index) => {
      return (
        <RevealExerciseCard key={index} index={index} onClick={this.answerClicked}
          content={answer.content}
          isBlurred={this.inState([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING]) && answer.isBlurred}
          isCorrect={answer.correct}
          isActive={this.inState(STATES.QUESTION_ANSWERING) && answer.isActive}
          wasActivated={answer.wasActivated}
          isShowingFeedback={this.inState([STATES.QUESTION_ANSWERED, STATES.QUESTION_HIDING]) || (this.inState(STATES.QUESTION_ANSWERING) && !answer.isActive)}
        />
      );
    });
  };

  answerClicked = (index) => {
    if (this.inState(STATES.QUESTION_ANSWERING)) {
      this.setState((state) => {
        let {points, questions, questionIndex, lastClickedAnswerIndex, correctAnswersLeftByQuestion} = state;
        let answers = questions[questionIndex].answers;
        let clickedAnswer = answers[index];

        if (lastClickedAnswerIndex !== undefined && lastClickedAnswerIndex !== index) {
          answers[lastClickedAnswerIndex].isBlurred = true;
        }
        lastClickedAnswerIndex = index;

        if (clickedAnswer.isBlurred) {
          clickedAnswer.isBlurred = false;
          Sounds.click.play();
        } else {
          clickedAnswer.isActive = false;
          clickedAnswer.wasActivated = true;
          lastClickedAnswerIndex = undefined;

          this.chosenAnswerIds.push(clickedAnswer.id);

          if (clickedAnswer.correct) {
            points += POINTS_FOR_CORRECT_ANSWER;
            Sounds.success.play();

            correctAnswersLeftByQuestion[questionIndex] -= 1;
          } else {
            points = Math.max(POINTS_FOR_INCORRECT_ANSWER + points, 0);
            Sounds.error.play();
          }
        }

        return {
          correctAnswersLeftByQuestion,
          lastClickedAnswerIndex,
          questions,
          points,
        }
      }, () => {
        const {correctAnswersLeftByQuestion, questionIndex} = this.state;
        if (correctAnswersLeftByQuestion[questionIndex] === 0) {
          this.setCurrentState(STATES.QUESTION_ANSWERED);
        }
      })
    }
  };

  continueGame = () => {
    let {questionIndex, questions} = this.state;

    if (questionIndex + 1 < questions.length) {
      this.setCurrentStateSequence([STATES.QUESTION_HIDING, STATES.QUESTION_HIDDEN], ANIMATION_SPEED,
        this.nextQuestion);
    } else {
      this.setCurrentState(STATES.FINISHED, this.gameFinished, ANIMATION_SPEED);
    }
  };

  gameFinished = () => {
    this.finish(true, {
      chosenAnswerIds: this.chosenAnswerIds,
    })
  };

  nextQuestion = () => {
    if (!this.inState(STATES.QUESTION_HIDDEN)) {
      console.warn("nextQuestion should only be called in state QUESTION_HIDDEN");
    }

    this.setState((state) => {
      let {questionIndex} = state;
      questionIndex++;

      return {
        questionIndex,
        lastClickedAnswerIndex: undefined,
      }
    }, this.showQuestion);
  };
};