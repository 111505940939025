import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';

import './ChangingText.scss';

export default function ChangingText({content}) {
  const contentUpdateTimeout = useRef(0);
  const [currentContent, setCurrentContent] = useState("");
  const [hiding, setHiding] = useState(true);

  useEffect(() => {
    if (currentContent !== content) {
      if (!currentContent) {
        updateContentAction(content)();
      } else {
        setHiding(true);
        clearTimeout(contentUpdateTimeout.current);
        contentUpdateTimeout.current = setTimeout(updateContentAction(content), 500);
      }
    }
  }, [content, currentContent]);

  const updateContentAction = (newContent) => () => {
    setCurrentContent(newContent);
    if (newContent) {
      setHiding(false);
    }
  };

  return <div className={classNames("ChangingText", {"hiding": hiding})}>
    {currentContent}
  </div>
}