import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import _ from 'underscore';

import Card from "../components/Card";

import './css/QuestionsExercise.css'
import PointsBar from "../components/PointsBar";
import AnswerCard from "../components/AnswerCard";

const ANIMATION_SPEED = 1000;

class QuestionsExercise extends Component {

  static propTypes = {
    question: PropTypes.string,
    answers: PropTypes.array,
    answersType: PropTypes.string,
    goNextAction: PropTypes.func,
    timeForQuestion: PropTypes.number,
  };

  constructor(props) {
    super(props);

    let maxPoints = 0;
    for (let question of props.questions) {
      maxPoints += question.subquestions.length;
    }

    this.state = {
      visible: true,

      gameFinished: false,
      points: 0,
      maxPoints: maxPoints,
      playing: true,
      timeForQuestion: props.timeForQuestion,
      time: props.timeForQuestion,

      timeout: null,
      clockTimeout: null,
      showAnswers: true,
      showFeedback: false,

      answers: _.clone(props.answers),
      questions: _.clone(props.questions),
      currentQuestion: 0,
      currentSubquestion: 0,
    };

    for (let id in this.state.answers) {
      if (this.state.answers.hasOwnProperty(id)) {
        this.state.answers[id] = _.extend(this.state.answers[id], {
          active: true,
          visible: true,
          side: 'front',
          index: id,
        });
      }
    }

    for (let question of this.state.questions) {
      question.visible = true;
      question.subquestions = _.shuffle(question.subquestions);

      for (let id in question.subquestions) {
        if (question.subquestions.hasOwnProperty(id)) {
          question.subquestions[id] = _.extend(question.subquestions[id], {
            active: true,
            visible: true,
            index: id,
          });
        }
      }
    }
  }

  componentDidMount() {
    this.setState({
      clockTimeout: setTimeout(this._secondPassed, 1000),
    })
  }

  answerChosen = (answer) => {
    if (!this.state.playing) {
      return;
    }

    clearTimeout(this.state.clockTimeout);

    let subquestion = this.state.questions[this.state.currentQuestion].subquestions[this.state.currentSubquestion];
    let pointsChange = 0;

    if (answer.id === subquestion.id) {
      pointsChange = 1;

      new Audio('/sounds/success.mp3').play();
    } else {
      pointsChange = -1;

      new Audio('/sounds/error.mp3').play();
    }

    this.setState((prevState) => {
      prevState.answers[answer.index].side = 'back';

      return {
        points: prevState.points + pointsChange,
        answers: prevState.answers,
        playing: false,
        showFeedback: true,
      }
    })
  };

  hideFeedback = () => {
    new Audio('/sounds/click.mp3').play();

    this.setState((prevState) => {
      for (let answer of prevState.answers) {
        answer.side = 'front';
      }

      prevState.questions[prevState.currentQuestion].subquestions[prevState.currentSubquestion].visible = false;
      if (prevState.currentSubquestion === prevState.questions[prevState.currentQuestion].subquestions.length - 1) {
        prevState.questions[prevState.currentQuestion].visible = false;
      }

      return {
        showFeedback: false,
        timeout: setTimeout(this._prepareForNextQuestion, 2000),
        answers: prevState.answers,
        questions: prevState.questions,
        showAnswers: false,
      }
    })
  };

  _prepareForNextQuestion = () => {
    this.setState((prevState) => {
      let playing = true;
      let gameFinished = false;
      let showAnswers = true;
      let currentQuestion = prevState.currentQuestion;
      let currentSubquestion = prevState.currentSubquestion;
      let timeout = null;
      let visible = true;

      if (currentSubquestion < prevState.questions[currentQuestion].subquestions.length - 1) {
        currentSubquestion++;
      } else if (currentQuestion < prevState.questions.length - 1) {
        currentQuestion++;
        currentSubquestion = 0;
      } else {
        playing = false;
        gameFinished = true;
        showAnswers = false;
        timeout = setTimeout(this._goNext, 1000);
        visible = false;
      }


      return {
        playing: playing,
        finished: gameFinished,
        currentSubquestion: currentSubquestion,
        showAnswers: showAnswers,

        timeout: timeout,
        visible: visible,

        time: prevState.timeForQuestion,
        clockTimeout: !gameFinished ? setTimeout(this._secondPassed, 1000) : null,

        currentQuestion: currentQuestion,
      }
    });
  };

  _secondPassed = () => {
    if (this.state.playing) {
      this.setState((prevState) => {
        let currentTime = prevState.time - 1;
        let clockTimeout = null;
        let showFeedback = false;

        let timeout = null;
        let playing = true;

        if (currentTime === 0) {
          new Audio('/sounds/error.mp3').play();

          playing = false;
          showFeedback = true;
        } else {
          clockTimeout = setTimeout(this._secondPassed, 1000);
        }

        return {
          clockTimeout: clockTimeout,
          time: currentTime,

          showFeedback: showFeedback,
          playing: playing,
          timeout: timeout,
        }
      });
    }
  };

  goNext = () => {
    new Audio('/sounds/click.mp3').play();
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  };

  _goNext = () => {
    this.props.goNextAction(this.state.points);
  };

  render() {
    let questionInfo = this.state.questions[this.state.currentQuestion];
    let subquestionInfo = questionInfo.subquestions[this.state.currentSubquestion];
    let imageStyle = {
      'backgroundImage': "url('images/questions/" + questionInfo.header.image + ".jpg')"
    };

    let question = (
      <Card className={'question'} hidden={!questionInfo.visible}>
        <div className={'pure-u-1-3 image'} style={imageStyle} />
        <div className={'pure-u-2-3 text'}>
          <h1>
            {questionInfo.header.content}
          </h1>
          <CSSTransitionGroup
            transitionName='example'
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}
            transitionAppear={true}
            transitionAppearTimeout={ANIMATION_SPEED}
          >
          { subquestionInfo.visible &&
          <div>
            <p>
              {subquestionInfo.content}
            </p>
            { subquestionInfo.feedback && this.state.showFeedback &&
            <CSSTransitionGroup
              transitionName='example'
              transitionEnter={true}
              transitionEnterTimeout={ANIMATION_SPEED}
              transitionLeave={true}
              transitionLeaveTimeout={ANIMATION_SPEED}
              transitionAppear={true}
              transitionAppearTimeout={ANIMATION_SPEED}
            >
              <p className={'feedback'}>
                { subquestionInfo.feedback }
              </p>
            </CSSTransitionGroup>
            }
          </div>
          }
          </CSSTransitionGroup>
        </div>
      </Card>
    );

    let answers = this.state.answers.map((answer, i) => {
      let imageStyle = {
        backgroundImage: "url('/images/questions/" + answer.image + ".jpg')"
      };

      let front = (
        <div className={subquestionInfo.id === answer.id && this.state.showFeedback ? 'success' : ''}>
          <h1>{answer.content}</h1>
          <div className={'image'} style={imageStyle} />
        </div>
      );

      let back = subquestionInfo.id === answer.id ? (
        <div className={'feedback correct'}>
          <div className="pure-u-2-5 icon">
            <div className="vertical-helper"/>
            <div className="feedback-icon"><i className="fas fa-check" /></div>
          </div>
          <div className="pure-u-3-5 text">
            <div className="vertical-helper" />
            <div className="content">
              <h1>Świetnie!</h1>
            </div>
          </div>
        </div>
      ) : (
        <div className={'feedback error'}>
          <div className="pure-u-2-5 icon">
            <div className="vertical-helper"/>
            <div className="feedback-icon"><i className="fas fa-times" /></div>
          </div>
          <div className="pure-u-3-5 text">
            <div className="vertical-helper" />
            <div className="content">
              <h1>Ups...</h1>
              <p>Zła odpowiedź!</p>
            </div>
          </div>
        </div>
      );

      return [
        <AnswerCard
          answer={answer}

          type="text"
          side={answer.side}
          index={i}
          columns={1}

          clickCallback={this.answerChosen}
          front={front}
          back={back}

          key={i}
        />,
        ' ',
      ]
    });

    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
        <div className="QuestionsExercise">
          <div className="pure-g points-bar-container">
            <div className="pure-u-1-1">
              <PointsBar
                points={this.state.points} maxPoints={this.state.maxPoints}
                time={this.state.time} clockWarningSeconds={Math.ceil(this.props.timeForQuestion / 3)}
                instruction={this.props.instruction}
              />
            </div>
          </div>
          <div className="pure-g question-container">
            {question}
          </div>
          <div className="pure-g answers-container">
            <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={ANIMATION_SPEED}
              transitionEnter={true}
              transitionEnterTimeout={ANIMATION_SPEED}
              transitionLeave={true}
              transitionLeaveTimeout={ANIMATION_SPEED}>
              {this.state.showAnswers &&
              <div className="answers-row pure-u-1-1">
                {answers}
              </div>
              }
            </CSSTransitionGroup>
          </div>
          <CSSTransitionGroup
            transitionName="example"
            transitionAppear={true}
            transitionAppearTimeout={ANIMATION_SPEED}
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {this.state.showFeedback &&
              <div className="pure-g buttons-container">
                <div className="pure-u-1-1 center">
                  <Card classes="next-button next-question" onClick={this.hideFeedback}>
                    <p>Dalej</p>
                  </Card>
                </div>
              </div>
            }
          </CSSTransitionGroup>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}

export default QuestionsExercise;
