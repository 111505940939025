import React, { Component } from 'react';
import PropTypes from 'prop-types'
import ReactMarkdown from "react-markdown";
import classNames from 'classnames';

import AnimatedElement from "../AnimatedElement/AnimatedElement";

import './InstructionCard.scss';

class PointsBar extends Component {
  secondsTimeout;

  static propTypes = {
    'visible': PropTypes.bool,

    'mainText': PropTypes.string.isRequired,
    'markdown': PropTypes.bool,
    'small': PropTypes.bool,

    'countType': PropTypes.string,
    'countCurrent': PropTypes.number,
    'countMax': PropTypes.number,
  };

  static defaultProps = {
    'visible': true,
    'topText': '',
    'markdown': false,
    'small': false,
  };

  constructor(props) {
    super(props);

    this.state = {
      time: props.timeLimit,
    };
  }

  render() {
    let {mainText, small} = this.props;

    let topText = this.getTopText();
    if (this.props.markdown) {
      mainText = (
        <ReactMarkdown source={mainText} />
      );
    }

    return (
      <AnimatedElement visible={this.props.visible} className={classNames(
          "InstructionCard",
          {
            small
          }
        )} animation={AnimatedElement.AnimationTypes.slideLeft}
      >
        <div className="left-filler" />
        <div className="text">
          <div className="count">
            {topText}
          </div>
          <div className={classNames("content", {"markdown": this.props.markdown})}>
            {mainText}
          </div>
        </div>
      </AnimatedElement>
    );
  }

  getTopText = () => {
    let {countType, countCurrent, countMax} = this.props;

    if (countType !== undefined && countCurrent !== undefined && countMax !== undefined) {
      return `${countType} ${countCurrent} z ${countMax}`
    } else {
      return '';
    }
  }
}



export default PointsBar;
