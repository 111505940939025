import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './AnswerCard.scss';
import Sounds from "../../lib/Sounds";

export default class AnswerCard extends Component {
  static propTypes = {
    showFeedback: PropTypes.bool,
    disabled: PropTypes.bool,

    isCorrect: PropTypes.bool,
    isBlurred: PropTypes.bool,
    answer: PropTypes.any,

    onClick: PropTypes.func,
    onBlurredClick: PropTypes.func,
  };

  static defaultProps = {
    showFeedback: false,
    disabled: false,
    isCorrect: false,

    onClick: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      showFeedback: false,
    }
  }

  render() {
    let {disabled, isCorrect, isBlurred} = this.props;
    let {showFeedback} = this.state;

    return (
      <div onClick={this.clicked} className={classNames(
        "AnswerCard",
        {
          "show-feedback": showFeedback,
          "correct": isCorrect,
          "blurred": isBlurred,
          "disabled": disabled && ! showFeedback,
        })}
      >
        <div className="disabled-overlay" />
        <div className="content">
          { this.props.children }
        </div>
        <div className={classNames(
          "feedback",
          {
            "correct": showFeedback && isCorrect,
          })}
        >
          {isCorrect && [
              <i className="fas fa-check" key="icon" />,
              <div className="feedback-description" key="description">
                <h2>Świetnie!</h2>
              </div>
            ]
          }
          {!isCorrect && [
            <i className="fas fa-times" key="icon" />,
            <div className="feedback-description" key="description">
              <h2>Ups...</h2>
              <p>Zła odpowiedź!</p>
            </div>
          ]
          }
        </div>
      </div>
    );
  }

  clicked = (event) => {
    let {onClick, onBlurredClick, disabled, answer, isCorrect, isBlurred} = this.props;
    let {showFeedback} = this.state;
    event.persist();

    if (!disabled && !showFeedback) {
      if (isBlurred) {
        onBlurredClick(answer, event);
      } else {
        this.setState({
          showFeedback: true,
        }, () => {
          if (isCorrect) {
            Sounds.success.play();
          } else {
            Sounds.error.play();
          }

          onClick(answer, event);
        });
      }
    }
  }
}