import React, { Component } from 'react';
import {CSSTransitionGroup} from "react-transition-group";

import Card from "../components/Card";

import './css/VideoScreen.css'

const ANIMATION_SPEED = 1000;

class VideoScreen extends Component {
  timer = null;

  constructor(props) {
    super(props);

    this.state = {
      showNext: false,
    };

    this.onNext = this.onNext.bind(this);
    this._showNextButton = this._showNextButton.bind(this);
  }

  componentDidMount() {
    this.timer = setTimeout(this._showNextButton, 6000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  _showNextButton() {
    this.setState({
      showNext: true,
    })
  }

  onNext() {
    new Audio('/sounds/click.mp3').play();

    this.setState({
      visible: false,
    });

    setTimeout(this.props.goNextAction, ANIMATION_SPEED);
  }

  render() {
    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        <div className="VideoScreen">
          <div className="pure-g title-container">
            <div className="pure-u-1-1">
              <Card>
                <h1>Na co zwrócić uwagę?</h1>
              </Card>
            </div>
          </div>
          <div className="pure-g video-container">
            <div className="pure-u-1-1">
              <Card classes="video">
                <video controls autoPlay>
                  <source src="video/sample.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Card>
            </div>
          </div>
          <div className="pure-g buttons-container">
            <div className="pure-u-1-1 center">
              <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={ANIMATION_SPEED}
                transitionEnter={true}
                transitionEnterTimeout={ANIMATION_SPEED}
                transitionLeave={true}
                transitionLeaveTimeout={ANIMATION_SPEED}>
                {this.state.showNext &&
                <Card classes="next-button" onClick={this.onNext}>
                  <p>Przejdź dalej</p>
                </Card>
                }
              </CSSTransitionGroup>
            </div>
          </div>
        </div>
      </CSSTransitionGroup>
    );
  }
}

export default VideoScreen;
