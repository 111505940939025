import React from 'react';
import classNames from 'classnames';

import logoImg from "../../../img/logo-white.svg";
import Card from "../../../components/Card/Card";

import "./MemoryCard.scss";

export default function MemoryCard({id, content, onClick, image, revealed, answered}) {
  function cardClicked() {
    onClick(id);
  }

  return (
    <Card
      className={classNames("MemoryCard", {
        "revealed": revealed,
        "answered": answered,
        "with-text": !!content,
      })}
      onClick={cardClicked}
    >
      <div className="front">
        <div className="text">{content}</div>
        <div className="image"  style={{backgroundImage: `url(${image})`}} />
      </div>
      <div className="back" style={{backgroundImage: `url(${logoImg})`}} />
    </Card>
  );
}