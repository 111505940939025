export default class Images {
  static SIZES = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
  };

  static PROPORTIONS = {
    P3x2: "3x2"
  };

  static EXTENSIONS = {
    JPG: "jpg",
    PNG: "png",
  };

  static getImageUrl(
    name,
    size=Images.SIZES.MEDIUM,
    proportions=Images.PROPORTIONS.P3x2,
    extension=Images.EXTENSIONS.JPG,
  ) {
    return `images/${size}/${proportions}/${name}.${extension}`;
  }

  static getCSSImageUrl(...parameters) {
    return `url("${Images.getImageUrl(...parameters)}")`
  }
}