import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

import Sounds from '../../lib/Sounds'

import './Button.scss';
import _ from "underscore";

export default class Button extends Component {
  static propTypes = {
    'className': PropTypes.string,
    'onClick': PropTypes.func,
    'disabled': PropTypes.bool,

    'round': PropTypes.bool,
    'big': PropTypes.bool
  };

  static defaultProps = {
    'disabled': false,

    'big': false,
  };

  buttonRef;

  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();
  }

  render() {
    return (
      <div className={classNames("Button", this.props.className, {"big": this.props.big, "disabled": this.props.disabled, "round": this.props.round})}
        onClick={this.clicked} ref={this.buttonRef}
      >
        { this.props.children }
      </div>
    );
  }

  clicked = _.throttle(() => {
    if (!this.props.disabled) {
      Sounds.click.play();
      this.props.onClick();
    }
  }, 1000, {trailing: false});
}