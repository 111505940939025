import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import Draggable from 'react-draggable';
import _ from 'underscore';

import Card from "../components/Card";

import './css/DefinitionExercise.css'
import PointsBar from "../components/PointsBar";
import mojs from "mo-js";

const ANIMATION_SPEED = 1000;
const TIME_TO_ANSWER = 5;

class DefinitionExercise extends Component {
  answerImageCards = {};
  questionCard = null;
  dragStart = null;
  burst = new mojs.Burst({
    left: 0, top: 0,
    // radius:   { 4: RADIUS },
    angle:    45,
    count:    20,
    timeline: { delay: 0 },
    children: {
      // radius:       10,
      fill:         '#FD7932',
      scale:        { 1: 0, easing: 'quad.in' },
      pathScale:    [ .8, null ],
      degreeShift:  [ 13, null ],
      duration:     [ 500, 700 ],
      easing:       'quint.out'
    },
    onStart: () => {
      this.burst.el.style.zIndex = 12;
    },
    onComplete: () => {
      this.burst.el.style.zIndex = -1;
    },
  });
  burst2 = new mojs.Burst({
    left: 0, top: 0,
    // radius:   { 4: RADIUS },
    angle:    60,
    count:    30,
    timeline: { delay: 100 },
    children: {
      // radius:       10,
      fill:         '#FD7932',
      scale:        { 1: 0, easing: 'quad.in' },
      pathScale:    [ .8, null ],
      degreeShift:  [ 13, null ],
      duration:     [ 500, 700 ],
      easing:       'quint.out'
    },
    onStart: () => {
      this.burst2.el.style.zIndex = 12;
    },
    onComplete: () => {
      this.burst2.el.style.zIndex = -1;
    },
  });
  burst3 = new mojs.Burst({
    left: 0, top: 0,
    // radius:   { 4: RADIUS },
    angle:    20,
    count:    30,
    timeline: { delay: 200 },
    children: {
      // radius:       10,
      fill:         '#FD7932',
      scale:        { 1: 0, easing: 'quad.in' },
      pathScale:    [ .8, null ],
      degreeShift:  [ 13, null ],
      duration:     [ 500, 700 ],
      easing:       'quint.out'
    },
    onStart: () => {
      this.burst3.el.style.zIndex = 12;
    },
    onComplete: () => {
      this.burst3.el.style.zIndex = -1;
    },
  });

  static propTypes = {
    instruction: PropTypes.string,
    answers: PropTypes.array,
    questions: PropTypes.array,

    goNextAction: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      started: false,
      playing: false,

      dragging: false,
      clockRunning: false,
      timeRanOut: false,
      gameFinished: false,
      points: 0,
      showSummary: false,

      timeout: null,

      answers: _.clone(props.answers),
      questions: _.shuffle(_.clone(props.questions)),
      currentQuestionIndex: 0,

      answerTimeout: null,
      answerTimer: TIME_TO_ANSWER,

      feedback: null,
    };

    for (let id in this.state.answers) {
      if (this.state.answers.hasOwnProperty(id)) {
        this.state.answers[id] = _.extend(this.state.answers[id], {
          active: true,
          visible: true,
          index: id,
        });
      }
    }

    for (let id in this.state.questions) {
      if (this.state.questions.hasOwnProperty(id)) {
        this.state.questions[id] = _.extend(this.state.questions[id], {
          active: true,
          visible: true,
          index: id,
        });
      }
    }
  }

  componentDidMount() {
    this._updateAnswersPosition();
  }

  componentDidUpdate() {
    this._updateAnswersPosition()
  }

  _startAnswerTimer() {
    this.setState({
      answerTimer: TIME_TO_ANSWER,
      answerTimeout: setTimeout(this._updateAnswerTimer, 1000),
    });
  }

  _updateAnswerTimer = () => {
    let timeLeft = null;
    this.setState((prevState) => {
      timeLeft = prevState.answerTimer - 1;
      return {
        answerTimeout: timeLeft > 0 ? setTimeout(this._updateAnswerTimer, 1000): null,
        answerTimer: timeLeft,
      }
    });

    if (!timeLeft) {
      this._explodeQuestion();
      this._incorrectChosen();
    }
  };

  _explodeQuestion = () => {
    let el = this.questionCard.DOM.getBoundingClientRect();
    let x = el.x;
    let y = el.y;
    let w = el.width;
    let h = el.height;
    this.burst.tune({ x: x + w / 2, y: y + h / 2, radius: {4: el.height}, children: { radius: el.height / 10} }).replay();
    this.burst2.tune({ x: x + w / 5, y: y + h / 4 , radius: {4: el.height / 2}, children: { radius: el.height / 5} }).replay();
    this.burst3.tune({ x: x + w / 2 + w / 4, y: y + h / 2 + h / 7, radius: {4: el.height / 2}, children: { radius: el.height / 5} }).replay();
  };

  _updateAnswersPosition = () => {
    for (let index in this.answerImageCards) {
      let answerImageCard = this.answerImageCards[index];
      let cardRect = answerImageCard.card.DOM.getBoundingClientRect();

      answerImageCard['position'] = {
        topLeft: {
          x: cardRect.x,
          y: cardRect.y
        },
        bottomRight: {
          x: cardRect.x + cardRect.width,
          y: cardRect.y + cardRect.height
        }
      }
    }
  };

  _correctChosen = () => {
    new Audio('/sounds/success.mp3').play();
    let timeout = setTimeout(this.hideFeedback, 3000);
    this.setState((prevState) => {
      let question = prevState.questions[prevState.currentQuestionIndex];
      question['visible'] = false;
      question['active'] = false;

      return {
        timeout: timeout,
        feedback: 'correct',
        points: prevState.points + 1,
        questions: prevState.questions,
        playing: false,
      }
    });
  };

  _incorrectChosen = () => {
    new Audio('/sounds/error.mp3').play();
    let timeout = setTimeout(this.hideFeedback, 3000);
    this.setState((prevState) => {
      let question = prevState.questions[prevState.currentQuestionIndex];
      question['visible'] = false;
      question['active'] = false;

      return {
        timeout: timeout,
        feedback: 'error',
        questions: prevState.questions,
        playing: false,
      }
    });
  };

  startGame = () => {
    if (!this.state.started) {
      let timeout = setTimeout(() => {
        this._startAnswerTimer();
        this.setState({
          playing: true,
        })
      }, 500);
      this.setState({
        started: true,
        timeout: timeout,
      });
      new Audio('/sounds/click.mp3').play();
    }
  };



  answerChosen = (answer, imageAnswer) => {
    if (!this.state.playing) {
      return;
    }

    this.setState((prevState) => {
      if (prevState.answerTimeout) {
        clearTimeout(prevState.answerTimeout);
      }

      return {
        answerTimeout: null,
      }
    });

    if (imageAnswer && answer.id === imageAnswer.id) {
      // correct
      this._correctChosen();

    } else {
      // error
      this._incorrectChosen();

    }
  };

  hideFeedback = () => {
    let timeout = null;
    let gameFinished = false;

    if (this.state.currentQuestionIndex < this.state.questions.length - 1) {
    // if (this.state.currentQuestionIndex < 0) {
      timeout = setTimeout(this.nextQuestion, 1000);
    } else {
      gameFinished = true;
    }

    this.setState({
      timeout: timeout,
      feedback: null,
      finished: gameFinished,
    });
  };

  nextQuestion = () => {
    this.setState((prevState) => {
      return {
        timeout: null,
        currentQuestionIndex: prevState.currentQuestionIndex + 1,
        playing: true,
      }
    });
    this._startAnswerTimer();
  };

  goNext = () => {
    new Audio('/sounds/click.mp3').play();
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  };

  _goNext = () => {
    this.props.goNextAction(this.state.points);
  };

  answerDragged = (event, target, answer) => {
    this.setState({
      dragging: false,
    });

    let node = target.node.getBoundingClientRect();
    let [x, y, width, height] = [node.x, node.y, node.width, node.height];
    let corners = [
      { x: x, y: y },
      { x: x + width, y: y },
      { x: x, y: y + height },
      { x: x + width, y: y + height },
    ];

    let draggedAnswer = this._findImageCard(event.clientX, event.clientY);
    if (!draggedAnswer) {
      draggedAnswer = this._findCrossingImageCard(corners);
    }

    if (draggedAnswer) {
      this.answerChosen(answer, draggedAnswer);
    }
  };

  _findImageCard = (x, y)  => {
    for (let imageCardId of Object.keys(this.answerImageCards)) {
      let imageCard = this.answerImageCards[imageCardId];
      if (imageCard.answer.active && imageCard.position) {
        if ((imageCard.position.topLeft.x < x && x < imageCard.position.bottomRight.x)
          && (imageCard.position.topLeft.y < y && y < imageCard.position.bottomRight.y)
        ) {
          return imageCard.answer;
        }
      }
    }

    return false;
  };

  _findCrossingImageCard = (corners) => {
    let [answer, newAnswer] = [false, false];
    for (let corner of corners) {
      newAnswer = this._findImageCard(corner.x, corner.y);
      if (answer && newAnswer && newAnswer.id !== answer.id) {
        answer = false;
        break;
      } else if (newAnswer) {
        answer = newAnswer;
      }
    }

    return answer;
  };

  render() {
    let answers = this.state.answers.map((answer, index) => {
      return (
        <Card classes="answer" key={index}
              saveDOM={true} ref={(element) => {this.answerImageCards[index] = {card: element, answer: answer}}}>
          <p>{ answer.content }</p>
          <img src={'images/definition/' + answer.image + '.jpg'} alt={'Zdjęcie ' + answer.content} />
          <ol className={'feedback-list ' + (this.state.finished ? 'show' : 'hide')}>
            {this.state.finished &&
              this.props.questions.map((question, index) => {
                return question.id === answer.id ? <li key={index}>{question.content}</li> : ''
              })
            }
          </ol>
        </Card>
      );
    });

    let questionCard = '';
    let feedbackCard = '';
    if (this.state.currentQuestionIndex < this.state.questions.length) {
      let question = this.state.questions[this.state.currentQuestionIndex];
      let shakeClass = '';
      if (this.state.answerTimer <= 1) {
        shakeClass = 'shake-hard';
      } else if (this.state.answerTimer <= 2) {
        shakeClass = 'shake';
      } else if (this.state.answerTimer <= 3) {
        shakeClass = 'shake-little';
      }

      questionCard = (
        <Draggable
            handle='.handle'
            defaultPosition={{x: 0, y: 0}}
            position={{x: 0, y: 0}}
            onStart={(e, t) => {
              this.setState({dragging: true});
            }}
            onStop={(e, t) => this.answerDragged(e, t, question)}
        >
          <div className={'handle'}>
            <div className={'question-elevator' + (question.active && this.state.playing ? ' moving' : '')
                + (this.state.dragging ? ' dragging' : '')}>
              <Card classes={'question' + (question.visible ? ' shake-constant ' + shakeClass : ' no-grabbing')
                      + (question.active ? ' active' : '') + (question.active && this.state.playing ? ' moving' : '') }
                    answer={question} side={question.side} hidden={!question.visible}
                    saveDOM={true} ref={(element) => this.questionCard = element}
              >
                <p>
                  {question.content}
                </p>
              </Card>
            </div>
          </div>
        </Draggable>
      );
      feedbackCard = this.state.feedback ?
        <Card classes={'feedback ' + this.state.feedback}>
          <p>{ this.state.feedback === 'correct' ? question.correct : question.error }</p>
        </Card> : '';
    }


    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={ANIMATION_SPEED}
        transitionEnter={true}
        transitionEnterTimeout={ANIMATION_SPEED}
        transitionLeave={true}
        transitionLeaveTimeout={ANIMATION_SPEED}>
        {this.state.visible &&
        <div className="DefinitionExercise">
          <div className="pure-g points-bar-container">
            <div className="pure-u-1-1">
              <PointsBar
                points={this.state.points} maxPoints={this.state.questions.length}
                hideClock={true}
                instruction={this.props.instruction}
              />
            </div>
          </div>
          <CSSTransitionGroup
            transitionName="example"
            transitionAppear={true}
            transitionAppearTimeout={ANIMATION_SPEED}
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>
            {!this.state.started &&
            <div className='buttons-container start-button pure-g'>
              <div className="pure-u-1-1">
                <Card classes='next-button' onClick={this.startGame}>
                  <p>Rozpocznij zadanie!</p>
                </Card>
              </div>
            </div>
            }
          </CSSTransitionGroup>
          <CSSTransitionGroup
            transitionName="example"
            transitionAppear={true}
            transitionAppearTimeout={ANIMATION_SPEED}
            transitionEnter={true}
            transitionEnterTimeout={ANIMATION_SPEED}
            transitionLeave={true}
            transitionLeaveTimeout={ANIMATION_SPEED}>

            <div className="pure-g answers-container">
              <CSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={ANIMATION_SPEED}
                transitionEnter={true}
                transitionEnterTimeout={ANIMATION_SPEED}
                transitionLeave={true}
                transitionLeaveTimeout={ANIMATION_SPEED}>
                {this.state.timeRanOut &&
                <Card classes='timeFinished pure-u-1-1'>
                  <h1>Koniec czasu!</h1>
                </Card>
                }
              </CSSTransitionGroup>
              <div className={'answers-row pure-u-1-4'}>
                {answers[0]}
              </div>
              <div className={'question-row pure-u-1-2'}>
                <CSSTransitionGroup
                  transitionName="example"
                  transitionEnter={true}
                  transitionEnterTimeout={ANIMATION_SPEED}
                  transitionLeave={true}
                  transitionLeaveTimeout={ANIMATION_SPEED}>
                  { this.state.started && questionCard }
                </CSSTransitionGroup>
                <CSSTransitionGroup
                  transitionName="example"
                  transitionEnter={true}
                  transitionEnterTimeout={ANIMATION_SPEED}
                  transitionLeave={true}
                  transitionLeaveTimeout={ANIMATION_SPEED}>
                  {this.state.finished &&
                  <div className="pure-g buttons-container">
                    <div className="pure-u-1-1 center">
                      <Card classes="next-button" onClick={this.goNext}>
                        <p>Przejdź dalej</p>
                      </Card>
                    </div>
                  </div>
                  }
                </CSSTransitionGroup>
              </div>
              <div className={'answers-row pure-u-1-4'}>
                {answers[1]}
              </div>
            </div>

          </CSSTransitionGroup>
          <div className="pure-g feedback-container">
            <div className={'pure-u-1-1'}>
              <CSSTransitionGroup
                transitionName="example"
                transitionEnter={true}
                transitionEnterTimeout={ANIMATION_SPEED}
                transitionLeave={true}
                transitionLeaveTimeout={ANIMATION_SPEED}>
                {this.state.feedback &&
                  feedbackCard
                }
              </CSSTransitionGroup>
            </div>
          </div>
        </div>
        }
      </CSSTransitionGroup>
    );
  }
}

export default DefinitionExercise;
