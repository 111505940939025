import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import {faAngleDoubleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Card from "sprint/components/Card/Card";
import Button from "sprint/components/Button/Button";

import "./ElevatorButtons.scss";

export default function ElevatorButtons({
  currentFloor, floorCount,
  changeFloorAction, goToNextFloor,
  floorButtonsEnabled, nextButtonEnabled, nextButtonVisible}) {
  const floors = _.rangeRight(floorCount).map((floor) => {
    return <ElevatorButton key={floor}
      floorName={floor + 1} selected={currentFloor === floor}
      onClick={changeFloorAction(floor)} enabled={floorButtonsEnabled}
    />
  });

  return (
    <Card className={classNames("ElevatorButtons", {"floors-disabled": !floorButtonsEnabled})} color={Card.COLORS.BRIGHT}>
      {floors}
      {nextButtonVisible &&
      <Button round className={"next-floor"} onClick={goToNextFloor} disabled={!nextButtonEnabled}>
        <FontAwesomeIcon icon={faAngleDoubleUp} size="lg"/> Go
      </Button>
      }
    </Card>
  )
}

function ElevatorButton({floorName, selected, onClick, enabled}) {
  return (
    <Button round onClick={onClick} className={classNames({selected})} disabled={!enabled}>
      {floorName}
    </Button>
  )
}
