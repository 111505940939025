import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card, {ButtonCard} from "../components/Card";

import './css/HelpScreen.css'

class ModalScreen extends Component {
  static propTypes = {
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,

    onClose: PropTypes.func,

    title: PropTypes.string,
    description: PropTypes.string,
  };

  render() {
    return (
        <div className="HelpScreen" onClick={this.props.onClose}>
          <div className="outer" style={{'height': this.props.maxHeight}}>
            <div className="middle">
              <div className="inner" style={{'width': this.props.maxWidth, 'height': this.props.maxHeight}}>
                <Card>
                  <h1>{this.props.title}</h1>
                  <p>{this.props.description}</p>
                  {this.props.button &&
                  <ButtonCard onClick={this.props.button.action}>{this.props.button.message}</ButtonCard>
                  }
                  <p className="close-information">Kliknij gdziekolwiek, aby zamknąć to okienko.</p>
                </Card>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default ModalScreen;
