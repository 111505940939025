import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import MyComponent from "../../base/MyComponent";

import PointsBar from "../../components/PointsBar/PointsBar";
import AnswerCard from "../../components/AnswerCard/AnswerCard";
import Animations from "../../lib/Animations";

import InstructionCard from "../../components/InstructionCard/InstructionCard";
import './QuizExercise.scss'
import Button from "../../components/Button/Button";
import AnimatedElement from "../../components/AnimatedElement/AnimatedElement";
import Container from "../../components/Container/Container";

const ANIMATION_SPEED = 1000;

export const QUIZ_CORRECT_POINTS = 2;

const STATES = {
  STARTING: 0,
  QUESTION_APPEARING: 1,
  QUESTION_ANSWERING: 2,
  QUESTION_ANSWERED: 3,
  QUESTION_HIDING: 4,
  FINISHED: 5,
};

export default class QuizExercise extends MyComponent {
  static propTypes = {
    questions: PropTypes.array,
    parameters: PropTypes.shape({
      timePerQuestionSeconds: PropTypes.number,
      answersHidden: PropTypes.bool,
    }),
    goNextAction: PropTypes.func,
  };

  static defaultParameters = {
    timePerQuestionSeconds: 15,
    answersHidden: false,
  };

  parameters = {};
  chosenAnswerIds = [];
  timeout = null;

  constructor(props) {
    super(props);

    Animations.newBurst('correct', 'rgb(255, 120, 0)', 'rgb(0, 198, 24)');
    Animations.newBurst('incorrect', 'rgb(255, 120, 0)', 'rgb(255, 13, 0)');

    this.state = {
      current: STATES.STARTING,

      playing: true,
      visible: true,
      gameFinished: false,

      unblurredAnswer: undefined,

      points: 0,
      maxPoints: props.questions.length * QUIZ_CORRECT_POINTS,

      timePerQuestionSeconds: props.parameters.timePerQuestionSeconds,

      showAnswers: true,
      showQuestion: true,
      showFeedback: false,

      questions: _.clone(props.questions),
      currentQuestionIndex: 0,
    };

    this.parameters = _.defaults({}, this.props.parameters, QuizExercise.defaultParameters);

    this.prepareQuestions();
  }

  prepareQuestions = () => {
    const {answersHidden} = this.parameters;

    for (let question of this.state.questions) {
      question.answers = _.shuffle(question.answers);

      for (let answerIndex in question.answers) {
        if (question.answers.hasOwnProperty(answerIndex)) {
          let answer = question.answers[answerIndex];
          answer = _.extend(answer, {
            active: true,
            visible: true,
            blurred: answersHidden,
            showFeedback: false,
            index: answerIndex,
          })
        }
      }
    }
  };

  render() {
    const {timePerQuestionSeconds} = this.parameters;

    return (
      <AnimatedElement visible={!this.inState(STATES.FINISHED)}>
        <div className="QuizExercise">
          <PointsBar
            points={this.state.points} maxPoints={this.state.maxPoints}
            timeLimit={timePerQuestionSeconds} clockRunning={this.inState(STATES.QUESTION_ANSWERING)} clockId={this.state.currentQuestionIndex}
            onTimeRanOut={this.timeRanOut} clockWarningSeconds={Math.ceil(timePerQuestionSeconds / 3)}
          />
          <AnimatedElement visible={this.inState(STATES.STARTING)}>
            <Container>
              <Button onClick={this.startGame} big>
                Rozpocznij
              </Button>
            </Container>
          </AnimatedElement>
          <AnimatedElement visible={!this.inStates([STATES.STARTING, STATES.QUESTION_HIDING])} animation={AnimatedElement.AnimationTypes.slideLeft} durationMs={ANIMATION_SPEED}>
            <InstructionCard
              countType="Pytanie" countCurrent={this._currentQuestionCount()} countMax={this.props.questions.length}
              mainText={this._currentQuestionContent()}
            />
          </AnimatedElement>
          <AnimatedElement visible={!this.inStates([STATES.STARTING, STATES.QUESTION_APPEARING, STATES.QUESTION_HIDING])}>
            <div className="answers-container">
              {this.renderAnswers()}
            </div>
          </AnimatedElement>
          <AnimatedElement className="container-next" visible={this.inStates(STATES.QUESTION_ANSWERED)} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={2000}>
            <Button onClick={this.continueGame} big>
              Przejdź dalej
            </Button>
          </AnimatedElement>
        </div>
      </AnimatedElement>
    );
  }

  startGame = () => {
    this.setCurrentStateSequence([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING], ANIMATION_SPEED);
  };

  renderAnswers = () => {
    if (this.inState(STATES.STARTING)) {
      return '';
    } else {
      return this._getCurrentAnswers().map((answer, index) => {
        return (
          <AnswerCard isCorrect={answer.correct} isBlurred={answer.blurred} answer={answer}
            disabled={!this.inState(STATES.QUESTION_ANSWERING)} onClick={this.answerChosen} onBlurredClick={this.blurredChosen}
            key={index}
          >
            {answer.content}
          </AnswerCard>
        );
      });
    }
  };

  blurredChosen = (answer) => {
    if (!this.state.playing) {
      return;
    }

    this.setState((state) => {
      let {questions, currentQuestionIndex, unblurredAnswer} = state;

      if (unblurredAnswer) {
        questions[currentQuestionIndex].answers[unblurredAnswer.index].blurred = true;
      }
      unblurredAnswer = answer;
      questions[currentQuestionIndex].answers[answer.index].blurred = false;

      return {
        questions,
        unblurredAnswer,
      };
    });
  };

  answerChosen = (answer, event) => {
    if (!this.state.playing) {
      return;
    }

    let position = [event.clientX, event.clientY];
    let pointsChange = 0;

    this.chosenAnswerIds.push(answer.id);

    if (answer.correct) {
      pointsChange = QUIZ_CORRECT_POINTS;

      Animations.playBurst('correct', position);
    } else {
      Animations.playBurst('incorrect', position);
    }

    this.setCurrentState(STATES.QUESTION_ANSWERED, () => {
      this.setState((state) => {
        return {
          points: state.points + pointsChange,
        }
      }, this.unBlurCurrentAnswers);
    });
  };

  timeRanOut = () => {
    this.setCurrentState(STATES.QUESTION_ANSWERED, this.unBlurCurrentAnswers);
  };

  unBlurCurrentAnswers = () => {
    this.setState((state) => {
      let {questions, currentQuestionIndex} = state;
      for (let answer of questions[currentQuestionIndex].answers) {
        answer.blurred = false;
      }

      return {
        questions,
      }
    });
  };

  _getActiveQuestionForState = (state = this.state) => {
    return state.questions[state.currentQuestionIndex]
  };

  continueGame = () => {
    let nextStep, nextState;
    if (this.isLastQuestionShown()) {
      nextState = STATES.FINISHED;
      nextStep = this._goNext
    } else {
      nextState = STATES.QUESTION_HIDING;
      nextStep = this.showNextQuestion
    }

    this.setCurrentState(nextState, () => {
      this.timeout = setTimeout(nextStep, ANIMATION_SPEED);
    });
  };

  isLastQuestionShown = () => {
    return this.state.currentQuestionIndex >= this.state.questions.length - 1;
  };

  showNextQuestion = () => {
    this.setState((state) => {
      return {
        currentQuestionIndex: state.currentQuestionIndex + 1,
      }
    }, () => {
      this.setCurrentStateSequence([STATES.QUESTION_APPEARING, STATES.QUESTION_ANSWERING], ANIMATION_SPEED);
    });
  };

  _goNext = () => {
    this.props.goNextAction({
      points: this.state.points,
      other: {
        chosenAnswerIds: this.chosenAnswerIds,
      }
    });
  };

  _currentQuestionContent = () => {
    return this._getActiveQuestionForState(this.state).content;
  };

  _currentQuestionCount = () => {
    return this.state.currentQuestionIndex + 1
  };

  _getCurrentAnswers = () => {
    return this._getActiveQuestionForState(this.state).answers
  };


  componentWillUnmount() {
    Animations.reset();
    clearTimeout(this.timeout);
  }
}
